import React, { useEffect, useState } from 'react';
import { Table, Input, Button, Modal, Space, Popconfirm, message, Col, Row, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { getAllCursoFortalecimiento, deleteMatriculado} from '../../services/cursoFortalecimiento/cursoFortalecimientoCVService';
import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';
import './TablaUsuarios.css';
import FormCV from './CursoCicloVidaForm';

const CursoCicloVida = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisibleForm, setIsModalVisible] = useState(false);
    const [codRegistro, setCodRegistro] = useState(null);
    const [filterCedula, setFilterCedula] = useState('');
    const [filterNombre, setFilterNombre] = useState('');
    const [filterCelular, setFilterCelular] = useState('');
    const [activeTabKey, setActiveTabKey] = useState("1");
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [resetSignal, setResetSignal] = useState(false);

    const appName = 'curso-fortalecimiento'; 
    const permissions = useSelector((state) => selectAppPermissions(state, appName));
    const permissionInsert = permissions.priv_insert === 'Y';
    const permissionUpdate = permissions.priv_update === 'Y';
    const permissionDelete = permissions.priv_delete === 'Y';

    const fetchUsuarios = async () => {
        setLoading(true);
        try {
            const users = await getAllCursoFortalecimiento();
            setData(users);
        } catch (error) {
            message.error('Error al cargar los usuarios');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsuarios();
    }, []);

    const handleDelete = async (oid) => {
        if (!oid || !oid.matriculado_id) {
            message.error('No se encontró el ID del participante a eliminar.');
            return;
        }
        try {
            await deleteMatriculado(oid.matriculado_id);
            message.success('Participante eliminado correctamente');
            fetchUsuarios(); 
        } catch (error) {
            message.error('Error al eliminar el participante, tiene sesiones asociadas');
            console.error('Error al eliminar:', error);
        }
    };
    

    const handleSuccess = () => {
        setIsModalVisible(false);
        setCodRegistro(null);
        fetchUsuarios();
    };

    const columns = [
        {
            title: 'Periodo academico',
            dataIndex: 'periodo_academico',
            key: 'periodo_academico',
        },
        {
            title: 'Fecha actividad',
            dataIndex: 'fecha_actividad',
            key: 'fecha_actividad',
            render: (text) => {
                const date = new Date(text);
                return new Intl.DateTimeFormat('es-ES', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                }).format(date);
            },
        },
        {
            title: 'ID Persona',
            dataIndex: 'pidm_persona',
            key: 'pidm_persona',
        },
        {
            title: 'ID Prospecto',
            dataIndex: 'prospecto_id',
            key: 'prospecto_id',
        }, {
            title: 'Identificación',
            dataIndex: 'identificacion_persona',
            key: 'identificacion_persona',
        }, {
            title: 'Nombre del matriculado',
            dataIndex: 'nombre_completo',
            key: 'nombre_completo',
        }, {
            title: 'Teléfono',
            dataIndex: 'telefono_contacto',
            key: 'telefono_contacto',
        }, {
            title: 'Correo electrónico',
            dataIndex: 'correo_electronico',
            key: 'correo_electronico',
        }, {
            title: 'Sede',
            dataIndex: 'sede',
            key: 'sede',
        }, {
            title: 'Descripción sede',
            dataIndex: 'descripcion_sede',
            key: 'descripcion_sede',
        }, {
            title: 'Rectoria',
            dataIndex: 'nombre_rectoria',
            key: 'nombre_rectoria',
        }, {
            title: 'Descripción rectoria',
            dataIndex: 'descripcion_rectoria',
            key: 'descripcion_rectoria',
        }, {
            title: 'Facultad',
            dataIndex: 'nombre_facultad',
            key: 'nombre_facultad',
        }, {
            title: 'Nivel',
            dataIndex: 'nombre_nivel',
            key: 'nombre_nivel',
        }, {
            title: 'Descripción nivel',
            dataIndex: 'descripcion_nivel',
            key: 'descripcion_nivel',
        }, {
            title: 'Codigo programa',
            dataIndex: 'codigo_corto_programa',
            key: 'codigo_corto_programa',
        }, {
            title: 'Codigo largo programa',
            dataIndex: 'codigo_largo_programa',
            key: 'codigo_largo_programa',
        }, {
            title: 'Programa',
            dataIndex: 'nombre_programa',
            key: 'nombre_programa',
        }, {
            title: 'Jornada académica',
            dataIndex: 'jornada_academica',
            key: 'jornada_academica',
        }, {
            title: 'SNP',
            dataIndex: 'snp',
            key: 'snp',
        }, {
            title: 'Costo inscripción',
            dataIndex: 'costo_inscripcion',
            key: 'costo_inscripcion',
        }, {
            title: 'Estado inscripción',
            dataIndex: 'estado_inscripcion',
            key: 'estado_inscripcion',
        }, {
            title: 'Estado admisión',
            dataIndex: 'estado_admision',
            key: 'estado_admision',
        }, {
            title: 'Codigo tipo admisión',
            dataIndex: 'codigo_tipo_admision',
            key: 'codigo_tipo_admision',
        }, {
            title: 'Tipo',
            dataIndex: 'tipo_admision',
            key: 'tipo_admision',
        }, {
            title: 'Número aplicacion',
            dataIndex: 'numero_aplicacion',
            key: 'numero_aplicacion',
        }, {
            title: 'Codigo materia',
            dataIndex: 'codigo_materia',
            key: 'codigo_materia',
        }, {
            title: 'Usuario',
            dataIndex: 'nombre_usuario',
            key: 'nombre_usuario',
        }, {
            title: 'Acciones',
            key: 'acciones',
            fixed: 'right',
            render: (_, record) => (
                <Space size="middle">
                    {permissionUpdate && (
                        <Tooltip title="Editar participante">
                            <Button type="primary" icon={<EditOutlined />} style={{ backgroundColor: '#f0ab18', color: '#ffffff' }} onClick={() => showModal(record)}
                            />
                        </Tooltip>
                    )} {permissionDelete && (
                        <Tooltip title="Eliminar participante">
                            <Popconfirm
                                title="¿Estás seguro de eliminar este usuario?"
                                onConfirm={() => handleDelete(record)}
                                okText="Sí"
                                cancelText="No"
                            >
                                <Button type="primary" danger icon={<DeleteOutlined />} />
                            </Popconfirm>
                        </Tooltip>
                    )}
                </Space>
            ),
        }
    ];

    const filteredData = data
    .filter(user => {
        return (
            (!filterCedula || (user.identificacion_persona && user.identificacion_persona.includes(filterCedula))) &&
            (!filterNombre || (user.nombre_completo && user.nombre_completo.toLowerCase().includes(filterNombre.toLowerCase()))) &&
            (!filterCelular || (user.telefono_contacto && user.telefono_contacto.includes(filterCelular)))
        );
    })
    .map((user, index) => ({
        ...user,
        key: user.oid_registro || `temp-key-${index}`,
    }));


    const handleClearFilters = () => {
        setFilterCedula('');
        setFilterNombre('');
        fetchUsuarios(); // Recargar los datos si es necesario
    };


    const showModal = (data) => {
        setIsModalVisible(true);
        setCodRegistro(data);
        setActiveTabKey("1");
    };

    const handleCancelSesion = () => {
        if (hasUnsavedChanges) {
            Modal.confirm({
                title: '¿Estás seguro de cerrar?',
                content: 'Si cierras el modal, perderás toda la información ingresada.',
                okText: 'Sí, cerrar',
                cancelText: 'No, regresar',
                onOk: () => {
                    setIsModalVisible(false);
                    setCodRegistro(null);
                    setActiveTabKey("1");
                    setResetSignal(!resetSignal);
                },
            });
        } else {
            setIsModalVisible(false);
            setCodRegistro(null);
            setActiveTabKey("1");
            setResetSignal(!resetSignal); 
        }
    };

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    return (
        <div className="container">
            <div className="filtro-container">
                <Row gutter={20}>
                    <Col span={8}>
                        <Input
                            placeholder="Filtrar por Cédula"
                            value={filterCedula || ''}
                            onChange={(e) => setFilterCedula(e.target.value)}
                            className="filtro-item"
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col span={8}>
                        <Input
                            placeholder="Filtrar por Nombre"
                            value={filterNombre || ''}
                            onChange={(e) => setFilterNombre(e.target.value)}
                            className="filtro-item"
                            style={{ width: '100%' }}
                        />
                    </Col>
                    <Col span={8}>
                        <Input
                            placeholder="Filtrar por Celular"
                            value={filterCelular || ''}
                            onChange={(e) => setFilterCelular(e.target.value)}
                            className="filtro-item"
                            style={{ width: '100%' }}
                        />
                    </Col>
                </Row>
                <Row gutter={6} style={{ marginBottom: 16 }}>
                    <Col span={12}>
                        <Tooltip title="Limpiar filtros">
                            <Button
                                icon={<SearchOutlined style={{ color: '#000000' }} />}
                                onClick={handleClearFilters}
                                style={{ backgroundColor: '#1677FF', color: '#ffffff' }}
                            >
                                Limpiar Filtros
                            </Button>
                        </Tooltip>
                    </Col>
                    {permissionInsert && (
                        <Col span={12}>
                            <Tooltip title="Crear Participantes">
                                <Button
                                    icon={<PlusOutlined />}
                                     onClick={() => showModal(null)}
                                    style={{ backgroundColor: '#3BB44A', color: '#ffffff' }}
                                >
                                    Registrar Matricula
                                </Button>
                            </Tooltip>
                        </Col>
                    )}
                </Row>
            </div>

            <div className="participant-count-container">
                <h1 className="participant-count-title">
                    Número de Matriculados: <span className="participant-count-number">{filteredData.length}</span>
                </h1>
            </div>
            <div className="tabla-container">
                <Table
                    className="table-curso-fortalecimiento"
                    columns={columns}
                    dataSource={filteredData}
                    rowKey="key"
                    loading={loading}
                    pagination={{
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        total: filteredData.length,
                        showSizeChanger: true,
                    }}
                    onChange={handleTableChange}
                    scroll={{ x: 'max-content' }}
                    size="small"
                    locale={{
                        emptyText: filteredData.length === 0 ? 'No se han registrado usuarios aún' : 'No hay usuarios disponibles en este momento',
                    }}
                />
            </div>
            <Modal
                title={'Matriculado'}
                open={isModalVisibleForm}
                width={1600}
                onCancel={handleCancelSesion}
                footer={null}
            >
                <FormCV registroData={codRegistro} resetSignal={resetSignal}  onSuccess={handleSuccess} onPendingChanges={setHasUnsavedChanges} activeTabKey={activeTabKey} setActiveTabKey={setActiveTabKey} />
            </Modal>
        </div>
    );
};
export default CursoCicloVida;