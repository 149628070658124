import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Form, Input, DatePicker, Space, Tooltip, message, Popconfirm } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { getPlanAccion, getAllPlanAccion, createPlanAccion, updatePlanAccion, removePlanAccion } from '../../services/planesPedagogicos/ipptaPlanAccionServices';

const PlanAccionTable = ({ oidRegistro }) => {
    
    const [dataSource, setDataSource] = useState([]);
    const [form] = Form.useForm();
    const [dataSourcePA, setDataSourcePA] = useState([]);
    const [editingKey, setEditingKey] = useState('');
    const [visible, setVisible] = useState(false);
    const [dataSelect, setDataSelect] = useState([]);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getPlanAccion(oidRegistro);
                setDataSource(data);
            } catch (error) {
                console.error('Error al obtener los datos:', error);
            }
        };
        fetchData();
    }, [oidRegistro]);

    let dimensionCounters = {};

    const columns = [
        {
            title: '#',
            dataIndex: 'dimension',
            key: 'dimension',
            width: 50,
            render: (text) => (
                <div
                    style={{
                        writingMode: 'vertical-rl',
                        transform: 'rotate(180deg)',
                        textAlign: 'center',
                        padding: '5px',
                        fontWeight: 'bold',
                    }}
                >
                    {text.toUpperCase()}
                </div>
            ),
            onCell: (record, rowIndex) => {
                const dimension = record.dimension;
                if (!dimensionCounters[dimension]) {
                    dimensionCounters[dimension] = 0;
                }
                const currentIndex = dimensionCounters[dimension];
                dimensionCounters[dimension] = currentIndex + 1;
                const dimensionData = dataSource.find(item => item.dimension === dimension);
                const totalRecords = dimensionData ? dimensionData.children.length : 0;
                const rowSpanValue = currentIndex % totalRecords === 0 ? totalRecords : 0;
                return {
                    rowSpan: rowSpanValue,
                };
            },

        },
        {
            title: 'Indicador',
            dataIndex: 'indicador',
            key: 'indicador',
            width: 10,
            render: (text) => (
                <div style={{ fontSize: '10px' }}>
                    {text}
                </div>
            ),
        },
        {
            title: 'Actividad',
            dataIndex: 'actividad',
            key: 'actividad',
            render: (actividad) => {
                return (
                    <div
                        style={{
                            fontSize: '10px',
                            paddingLeft: '20px',
                            wordWrap: 'break-word',  
                            whiteSpace: 'normal',    
                            textAlign: 'justify'     
                        }}
                    >
                        {actividad}
                    </div>
                );
            },
        },
        {
            title: 'Acción',
            dataIndex: 'accion',
            key: 'accion',
            width: 60,
            render: (_, record) => (
                <Tooltip title={record.actividad ? '' : 'Falta diligenciar plan de acción predial para la transacción agroecológica'}>
                <Button
                    type="link"
                    onClick={() => handleAction(record)}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: '20px',
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <PlusOutlined style={{ color: record.actividad ? '#8BBF44' : '#FF3232' }} />
                </Button>
                </Tooltip>
            ),
        },
    ];

    const handleEdit = (record) => {
        form.setFieldsValue({
            actividad: record.actividad,
            fecha: record.fecha ? dayjs(record.fecha) : null,
        });
        setIsEditing(true);
        setEditingKey(record.oid);
        setVisible(true);
    };

    const handleDelete = async (record) => {
        try {
            await removePlanAccion(record.oid);
            const newData = dataSourcePA.filter((item) => item.oid !== record.oid);
            setDataSourcePA(newData);
            message.success('Registro eliminado exitosamente');
        } catch (error) {
            console.error('Error al eliminar el registro:', error);
            message.error('Hubo un error al eliminar el registro');
        }
    };
    
    const handleAddOrUpdate = async () => {
        try {
            const actividad = form.getFieldValue('actividad');
            const fecha = form.getFieldValue('fecha');
            const formattedFecha = fecha ? fecha.toISOString().split('T')[0] : null;
            if (!actividad) {
                message.error('Por favor complete todos los campos');
                return;
            }
            if (isEditing) {
                const updatedData = {
                    oid: editingKey,
                    actividad,
                    fecha: formattedFecha,
                    oidIppta_general: oidRegistro,
                    oid_dimension: dataSelect?.oidDimension,
                    oid_indicador: dataSelect?.oidIndicador,
                };
                await updatePlanAccion(updatedData);
                setDataSourcePA((prevDataSource) =>
                    prevDataSource.map((item) =>
                        item.oid === editingKey ? { ...item, ...updatedData } : item
                    )
                );
                message.success('Registro actualizado exitosamente');
            } else {
                const newData = {
                    actividad,
                    fecha: formattedFecha,
                    oidIppta_general: oidRegistro,
                    oid_dimension: dataSelect?.oidDimension,
                    oid_indicador: dataSelect?.oidIndicador,
                };
                await createPlanAccion(newData);
                setDataSourcePA((prevDataSource) => [
                    ...prevDataSource,
                    { ...newData, key: prevDataSource.length + 1 },
                ]);
                message.success('Registro agregado exitosamente');
            }
            form.resetFields();
            setIsEditing(false);
            setEditingKey(null);
        } catch (error) {
            message.error('Hubo un error al procesar el registro');
        }
    };

    const handleCancel = async () => {
        try {
            const data = await getPlanAccion(oidRegistro);
            setDataSource(data);
            setVisible(false);
            form.resetFields();
            setIsEditing(false);
            setEditingKey(null);
        } catch (error) {
            console.error('Error al obtener los datos:', error);
        }
    };

    const columnsPA = [
        {
            title: 'Actividad',
            dataIndex: 'actividad',
            editable: true,
            render: (actividad) => {
                return (
                    <div
                        style={{
                            paddingLeft: '20px',
                            wordWrap: 'break-word',  
                            whiteSpace: 'normal',    
                            textAlign: 'justify'     
                        }}
                    >
                        {actividad}
                    </div>
                );
            },
        },
        {
            title: 'Fecha',
            dataIndex: 'fecha',
            editable: true,
            width: 50,
        },
        {
            title: 'Acciones',
            aling: 'center',
            width: 50,
            dataIndex: 'actions',
            render: (_, record) =>
                dataSource.length >= 1 ? (
                    <Space size="middle">
                        <Tooltip title="Editar">
                            <Button
                                icon={<EditOutlined />}
                                onClick={() => handleEdit(record)}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            />
                        </Tooltip>
                        <Tooltip title="Eliminar">
                <Popconfirm
                    title="¿Estás seguro de eliminar este registro?"
                    onConfirm={() => handleDelete(record)}
                    okText="Sí"
                    cancelText="No"
                >
                    <Button
                        icon={<DeleteOutlined />}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    />
                </Popconfirm>
            </Tooltip>
                    </Space>
                ) : null,
        },
    ];

    const handleAction = async (record) => {
        try {
            setDataSelect(record);
            const oidGeneral = oidRegistro;
            const oidDimension = record.oidDimension;
            const oidIndicador = record.oidIndicador;
            const data = await getAllPlanAccion(oidGeneral, oidDimension, oidIndicador);
            setVisible(true);
            setDataSourcePA(data);
        } catch (error) {
            console.error('Error al obtener los registros:', error);
        }
    };

    const cleanedDataSource = dataSource
        .map(item => {
            return item.children.map(child => {
                const actividades = child.children && Array.isArray(child.children)
                    ? child.children.map(activity => activity.actividad).join(' - ')
                    : '';
                const fechas = child.children && Array.isArray(child.children) ? child.children.map(activity => activity.fecha).join(' - ') : '';
                return {
                    dimension: item.dimension,
                    oidDimension: item.oidDimension,
                    indicador: child.indicador,
                    oidIndicador: child.oidIndicador,
                    actividad: actividades,
                    fecha: fechas,
                };
            });
        })
        .flat();

    return (
        <div>
            <Table
                dataSource={cleanedDataSource}
                columns={columns}
                pagination={false}
                bordered
                style={{ marginTop: 20 }}
                expandable={false}
            />
            <Modal
                open={visible}
                width={1000}
                title={dataSelect.dimension ? `${dataSelect.dimension.toUpperCase()}` : "SIN DIMENSIÓN SELECCIONADA"}
                onCancel={handleCancel}
                footer={null}
            >
                <p>Indicador: {dataSelect.indicador}</p>
                <Form
                    form={form}
                    layout="vertical"
                    name="planAccionForm"
                    initialValues={{
                        actividad: dataSourcePA.actividad,
                        fecha: dataSourcePA.fecha, 
                    }}
                >
                    <table style={{ width: '100%' }}>
                        <tbody>
                            <tr>
                                <td style={{ paddingRight: '16px', width: '60%' }}>
                                    <Form.Item
                                        name="actividad"
                                        label="Actividad"
                                        rules={[{ required: true, message: 'Por favor ingrese la actividad' }]}
                                    >
                                        <Input.TextArea rows={1} placeholder="Ingrese una actividad" />
                                    </Form.Item>
                                </td>
                                <td style={{ paddingLeft: '16px', width: '20%' }}>
                                    <Form.Item
                                        name="fecha"
                                        label="Fecha"
                                    >
                                        <DatePicker  placeholder="Fecha" />
                                    </Form.Item>
                                </td>
                                <td style={{ paddingLeft: '16px', width: '20%' }}>
                                    <Button type="dashed" onClick={handleAddOrUpdate} block>
                                        {isEditing ? 'Actualizar Registro' : 'Agregar Registro'}
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Form>
                <Table
                    bordered
                    dataSource={dataSourcePA}
                    columns={columnsPA}
                    rowClassName="editable-row"
                    pagination={{
                        pageSize: 5, 
                        showSizeChanger: false, 
                    }}
                />
            </Modal>
        </div>
    );
};

export default PlanAccionTable;
