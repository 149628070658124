import React, { useEffect, useState } from 'react';
import { green, red, yellow, orange } from '@ant-design/colors';
import { Button, Table, Alert, Spin, Progress, Input, Select } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import {  ClearOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { getArbolDirectorios } from '../../services/dashboardInicial/getArbolDirectorios';
import { useSelector } from 'react-redux';
import { selectAppPermissions } from '../../store/auth/selectors';
import './Participantes.css';
import getParticipantes from '../../services/participant/participantesServices';
import ExportToExcelParticipantes from './exportExcelPartcipantes';
import { set } from 'lodash';

const { Option } = Select;

const Participantes = ({ proyectoCodigo }) => {

  const [participantes, setParticipantes] = useState([]);
  const [filterParticipantes, setFilterParticipantes] = useState([]);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [viewMode] = useState('table'); 
  const [listaDepartamentos, setListaDepartamentos] = useState([]);
  const [selectedDepartamento, setSelectedDepartamento] = useState(null);
  const [listaMunicipios, setListaMunicipios] = useState([]);
  const [listaMunicipiosFilter, setListaMunicipiosFilter] = useState([]);
  const [selectedMunicipio, setSelectedMunicipio] = useState(null);
  const [listaProyectos, setListaProyectos] = useState([]);
  const [selectedProyecto, setSelectedproyecto] = useState(null);
  const [numeroIdentificacion, setNumeroIdentificacion] = useState(null);
  const [pagination, setPagination] = useState({ pageSize: 30, current: 1 });
  const [sortOrder, setSortOrder] = useState({});
  const [sortField, setSortField] = useState('');
  const [filteredInfo, setFilteredInfo] = useState({});
  const location = useLocation();
  const { nombre_participante } = location.state || {};
  const appName = 'participantes';
  const permissions = useSelector((state) => selectAppPermissions(state, appName));
  const permissionExport = permissions.priv_export === 'Y';


  const getUniqueByValue = (data, key) => {
    const unique = data.reduce((acc, current) => {
      if (!acc[current[key]]) {
        acc[current[key]] = current;
      }
      return acc;
    }, {});
    return Object.values(unique);
  };

  const handleDepartamentoChange = (value) => {
    setSelectedMunicipio('')
    setSelectedDepartamento(value);
    const filteredList = listaMunicipios.filter(item => item.id_departamento === value);
    setListaMunicipiosFilter(filteredList)
  };

  const fetchPartcipantes = async () => {
    try {
      const { status, data } = await getParticipantes();
      setStatus(status);

      if (status === 200) {
        let filteredData = data;
        if (nombre_participante) {
          filteredData = data.filter(part => part.nombre_participante === parseInt(nombre_participante));
        }

            
        // Obtener el árbol de directorios
        const { data: dataDirectorios } = await getArbolDirectorios();
        // Recorremos los participantes para verificar archivos
        const participantesConArchivo = data.map(part => {

          // Define las rutas de búsqueda para el participante
          const rutas = [
            { ruta: `${part.codigo}/Participantes/${part.numero_identificacion}/Cédula`, campo: 'validado_registraduria', valor: 'X' },
            { ruta: `${part.codigo}/Participantes/${part.numero_identificacion}/Antecedentes Procuraduría`, campo: 'validado_procuraduria', valor: 'X' },
            { ruta: `${part.codigo}/Participantes/${part.numero_identificacion}/Antecedentes Policía`, campo: 'validado_policia', valor: 'X' },
            { ruta: `${part.codigo}/Participantes/${part.numero_identificacion}/RNMC`, campo: 'RNMC', valor: 'X' },
            { ruta: `${part.codigo}/Participantes/${part.numero_identificacion}/Antecedentes Contraloría`, campo: 'validado_contraloria', valor: 'X' }
          ];

          // Verifica los archivos para cada ruta y actualiza el campo correspondiente
          const updatedPart = { ...part };
          let progreso = 0;
          rutas.forEach(({ ruta, campo, valor }) => {
            // Convierte la ruta en una lista de nombres
            const rutaLista = transformarRutaARutaLista(ruta);

            // Verifica si el archivo existe
            if (contieneArchivo(dataDirectorios, rutaLista)) {
              progreso = progreso + 20;
              updatedPart[campo] = valor;
            } else {
              updatedPart[campo] = ''; // O cualquier valor que desees para el caso en que no se encuentra el archivo
            }
          });
          updatedPart['progress'] = progreso;
          return updatedPart;
        });

        setParticipantes(participantesConArchivo || [])
        setFilterParticipantes(participantesConArchivo || [])
        //Obtener  proyectos
        const uniqueDataProyecto = getUniqueByValue(filteredData, 'codigo');

        // Transformar en lista de objetos con proyecto_oid y value
        const filtersProyecto = uniqueDataProyecto.map(item => ({
          nombre_proyecto: item.proyecto,
          proyecto_codigo: item.codigo
        }));

        setListaProyectos(filtersProyecto);


        //Obtener departamentos
        const uniqueDataDepartamento = getUniqueByValue(filteredData, 'oid_departamento');

        // Transformar en lista de objetos con proyecto_oid y value
        const filtersDepartamentos = uniqueDataDepartamento.map(item => ({
          id_departamento: item.oid_departamento,
          departamento: item.departamento
        }));

        setListaDepartamentos(filtersDepartamentos);

        //Obtener municipios
        const uniqueDataMunicipio = getUniqueByValue(filteredData, 'oid_municipio');

        // Transformar en lista de objetos con proyecto_oid y value
        const filtersMunicipios = uniqueDataMunicipio.map(item => ({
          id_municipio: item.oid_municipio,
          municipio: item.municipio,
          id_departamento: item.oid_departamento
        }));

        setListaMunicipios(filtersMunicipios);
        setListaMunicipiosFilter(filtersMunicipios)

      } else {
        setParticipantes([]);
        setFilterParticipantes([])
      }
    } catch (error) {
      setStatus(500);
      setParticipantes([]);
      setFilterParticipantes([])

    } finally {
      setLoading(false);
    }
  };

  //Obtenemos la lista de participantes 
  useEffect(() => {

    fetchPartcipantes();

  }, [nombre_participante]);


  // Función para transformar una ruta de cadena a una lista de nombres
  function transformarRutaARutaLista(ruta) {
    return ruta.split('/').filter(segment => segment);
  }

  const contieneArchivo = (dataDirectorios, nombres) => {
    // Inicia la búsqueda desde el nivel más alto
    let currentNode = dataDirectorios.find(node => node.name === nombres[0]);

    // Iterar sobre los nombres proporcionados
    for (let i = 1; i < nombres.length; i++) {
      if (!currentNode || !currentNode.children) {
        // Si el nodo o sus hijos no existen, retornamos false
        return false;
      }

      // Avanzar al siguiente nodo en la jerarquía
      currentNode = currentNode.children.find(child => child.name === nombres[i]);
    }

    // Finalmente, verificar si el nodo final tiene archivos
    if (currentNode && currentNode.children && currentNode.children.length > 0) {
      return currentNode.children.some(child => child.type === "file");
    }

    return false;
  }


  //Funcion para mensajesdependiendo el estado de la solicitud al backend
  const renderErrorMessage = (status) => {
    if (status === 404) {
      return (
        <Alert type="warning" message="Advertencia" description="Lo sentimos, no se encuentran participantes disponibles para mostrar." showIcon icon={<FontAwesomeIcon icon={faExclamationTriangle} />} />
      );
    } else if (status === 500) {
      return (
        <Alert type="error" message="Error" description="Lo sentimos, ha ocurrido un error al obtener los participantes. Por favor, inténtelo de nuevo más tarde o contacte a soporte@comunix.co" showIcon icon={<FontAwesomeIcon icon={faExclamationCircle} />} />
      );
    }
  };


  const handleTableChange = (pagination, filters, sorter, extra) => {
    setPagination(pagination);
    setSortOrder(sorter.order);
    setSortField(sorter.field);
    setFilteredInfo(filters);
  };


  //Configuración d elas columnas d ela tabla
  const noDataConfig = {
    emptyText: (
      <span style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <InboxOutlined style={{ fontSize: '2rem' }} />
        <span>Sin resultados</span>
      </span>
    ),
  };


  // Define la función de formato de fecha
  const formatDate = (dateString) => {
    if (!dateString) return '';

    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses son 0-indexados
    const day = String(date.getDate()).padStart(2, '0'); // Asegura que el día tenga dos dígitos

    return `${day}/${month}/${year}`;
  }

  //Función para limpiar filtro de busqueda
  const clearAllFilters = async () => {
    setFilteredInfo({});
    fetchPartcipantes();
    setNumeroIdentificacion('');
    setSelectedproyecto('');
    setSelectedMunicipio('');
    setSelectedDepartamento('');

  };


  useEffect(() => { 
    if (proyectoCodigo) {
      setSelectedproyecto(proyectoCodigo);
      let filtered = participantes;
        filtered = filtered.filter(item =>
        item.codigo === selectedProyecto
      );
      setFilterParticipantes(filtered);
    }
  }, [participantes, proyectoCodigo, selectedProyecto]);
  


  useEffect(() => {
    filterData();
  }, [numeroIdentificacion, selectedDepartamento, selectedMunicipio, selectedProyecto]);
  
  //Funcion para manejar los filtros
  const filterData = () => {
    let filtered = participantes;

    if (numeroIdentificacion) {
      filtered = filtered.filter(item =>
        item.numero_identificacion.includes(numeroIdentificacion)
      );
    }

    if (selectedDepartamento) {
      filtered = filtered.filter(item =>
        item.oid_departamento === selectedDepartamento
      );
    }

    if (selectedMunicipio) {
      filtered = filtered.filter(item =>
        item.oid_municipio === selectedMunicipio
      );
    }

    if (selectedProyecto) {
      console.log(selectedProyecto)
      filtered = filtered.filter(item =>
        item.codigo === selectedProyecto
      );
    }

    setFilterParticipantes(filtered);
  };

 
  const columns = [
    {
      title: 'DATOS BASICOS',
      children: [
        {
          title: 'ID',
          dataIndex: 'oid',
          key: 'oid',
          width: 50,
          align: 'center',

        },
        {
          title: 'Nombre completo',
          dataIndex: 'nombre_participante',
          key: 'nombre_participante',
          width: 50,
          align: 'left',
        },
        {
          title: 'Tipo de Identificación',
          dataIndex: 'tipo_identificacion',
          key: 'tipo_identificacion',
          width: 150,
          align: 'left',

        },
        {
          title: 'Número de Identificación',
          dataIndex: 'numero_identificacion',
          key: 'numero_identificacion'
        },

        {
          title: 'Sexo',
          dataIndex: 'sexo',
          key: 'sexo',
          width: 50,
          align: 'center'
        },
        {
          title: 'Fecha de Nacimiento',
          dataIndex: 'fecha_nacimiento',
          key: 'fecha_nacimiento',
          width: 150,
          align: 'center',
          render: (text) => formatDate(text), // Aplica el formateo
        },
        {
          title: '¿Sabe leer y escribir?',
          dataIndex: 'sabe_leer_escribir',
          key: 'sabe_leer_escribir',
          width: 150,
          filterMode: 'tree',
          align: 'center'
        },
        {
          title: 'Ingresos Mensuales',
          dataIndex: 'ingresos_mensuales',
          key: 'ingresos_mensuales',
          width: 150,
          align: 'left'
        },

        {
          title: 'Línea Económica Principal',
          dataIndex: 'linea_economica',
          key: 'linea_economica',
          width: 100,
          align: 'left'
        },

        {
          title: 'Nivel de Escolaridad',
          dataIndex: 'nivel_escolaridad',
          key: 'nivel_escolaridad',
          width: 150,
          align: 'left'
        },

        {
          title: 'Estado dentro del proyecto',
          dataIndex: 'estado',
          key: 'estado',
          width: 150,
          align: 'left'
        },
      ],
    },

    {
      title: 'POBLACIÓN',
      children: [
        {
          title: 'Campesino',
          dataIndex: 'Campesino',
          key: 'Campesino',
          width: 110,
          align: 'Center'

        },
        {
          title: 'Comunidad Indígena',
          dataIndex: 'Comunidad_Indigena',
          key: 'Comunidad_Indigena',
          width: 120,
          align: 'Center'
        },
        {
          title: 'Comunidad NARP',
          dataIndex: 'Comunidad_NARP',
          key: 'Comunidad_NARP',
          width: 120,
          align: 'Center'
        },
        {
          title: 'Comunidad ROM',
          dataIndex: 'Comunidad_ROM',
          key: 'Comunidad_ROM',
          width: 120,
          align: 'center'
        },
        {
          title: 'Victima',
          dataIndex: 'Victima',
          key: 'Victima',
          width: 110,
          align: 'Center'
        },
        {
          title: 'Discapacidad',
          dataIndex: 'Discapacidad',
          key: 'Discapacidad',
          width: 130,
          align: 'Center'
        },
        {
          title: 'LGBT_plus',
          dataIndex: 'LGBT plus',
          key: 'LGBT plus',
          width: 120,
          align: 'center'
        },
        {
          title: 'Mujer rural',
          dataIndex: 'Mujer_Rural',
          key: 'Mujer_Rural',
          width: 110,
          align: 'Center'
        },

        {
          title: 'Joven rural',
          dataIndex: 'Joven_Rural',
          key: 'Joven_Rural',
          width: 110,
          align: 'Center'
        },
        {
          title: 'Adulto mayor',
          dataIndex: 'Adulto_Mayor',
          key: 'Adulto_Mayor',
          width: 110,
          align: 'Center'
        },
        {
          title: 'Reincorporado',
          dataIndex: 'Reincorporado',
          key: 'Reincorporado',
          width: 140,
          align: 'Center'
        },
        {
          title: 'Otro',
          dataIndex: 'Otro',
          key: 'Otro',
          width: 100,
          align: 'Center'
        },
      ],
    },

    {
      title: 'INFORMACIÓN FAMILIAR',
      children: [
        {
          title: 'Cabeza de Familia',
          dataIndex: 'cabeza_familia',
          key: 'cabeza_familia',
          width: 150,
          align: 'Center'
        },
        {
          title: 'Número de Personas a Cargo',
          dataIndex: 'num_personas_cargo',
          key: 'num_personas_cargo',
          width: 200,
          align: 'Center'
        },
        {
          title: 'Total Núcleo Familiar',
          dataIndex: 'total_nucleo_familiar',
          key: 'total_nucleo_familiar',
          width: 150,
          align: 'Center'
        },
        {
          title: 'Estado Civil',
          dataIndex: 'estado_civil',
          key: 'estado_civil',
          width: 150,
          align: 'left'
        },
        {
          title: 'Nombre del Cónyuge',
          dataIndex: 'nombre_conyuge',
          key: 'nombre_conyuge',
          width: 150,
          align: 'left'
        },
        {
          title: 'Tipo de Identificación Cónyuge',
          dataIndex: 'tipo_identificacion_conyuge',
          key: 'tipo_identificacion_conyuge',
          width: 200,
          align: 'left'
        },
        {
          title: 'Identificación Cónyuge',
          dataIndex: 'identificacion_conyuge',
          key: 'identificacion_conyuge',
          width: 150,
          align: 'left',
          filteredValue: null, // Ajusta el valor según sea necesario
        },
        {
          title: 'Teléfono Cónyuge',
          dataIndex: 'numero_contacto_conyuge',
          key: 'numero_contacto_conyuge',
          width: 150,
          align: 'left'
        },
      ],
    },


    {
      title: 'INFORMACIÓN DEL PROYECTO VINCULADO',
      children: [
        {
          title: 'Codigo proyecto',
          dataIndex: 'codigo',
          key: 'codigo',
          width: 150,
          align: 'left',
        },
        {
          title: 'Nombre proyecto',
          dataIndex: 'proyecto',
          key: 'proyecto',
          width: 150,
          align: 'left'
        },
        {
          title: 'Subregión PDET',
          dataIndex: 'nombre_subregion_pdet',
          key: 'nombre_subregion_pdet',
          width: 150,
          align: 'left'
        },
        {
          title: 'Departamento',
          dataIndex: 'departamento',
          key: 'departamento',
          sorter: {
            compare: (a, b) => a.departamento.localeCompare(b.departamento),
            multiple: 3,
          },
          width: 150
        },

        {
          title: 'Municipio',
          dataIndex: 'municipio',
          key: 'municipio',
          sorter: {
            compare: (a, b) => a.municipio.localeCompare(b.municipio),
            multiple: 3,
          },
          width: 150,
        },
        {
          title: 'Tipo de zona',
          width: 150,
          align: 'left',
          dataIndex: 'tipo_zona_influencia',
          key: 'tipo_zona_influencia'
        },
        {
          title: 'Nombre de zona',
          width: 150,
          align: 'left',
          dataIndex: 'nombre_zona',
          key: 'nombre_zona'
        },
        {
          title: 'Código shape veredas ART',
          width: 150,
          align: 'left',
          dataIndex: 'codigo_shape',
          key: 'codigo_shape'
        },
        {
          title: 'Nombre del Predio/Finca',
          width: 150,
          align: 'left',
          dataIndex: 'nombre_predio',
          key: 'nombre_predio'
        },
        {
          title: 'Relación de Tenencia con el Predio',
          width: 200,
          align: 'left',
          dataIndex: 'relacion_tenencia_predio',
          key: 'relacion_tenencia_predio'
        },
        {
          title: 'Latitud',
          width: 100,
          align: 'Center',
          dataIndex: 'latitud',
          key: 'latitud'
        },
        {
          title: 'Longitud',
          width: 100,
          align: 'Center',
          dataIndex: 'longitud',
          key: 'longitud'
        },
        {
          title: 'No. Hectáreas Destinadas al Proyecto',
          width: 150,
          align: 'left',
          dataIndex: 'num_hectarias_proyecto',
          key: 'num_hectarias_proyecto'
        },
        {
          title: 'Linea productiva 1',
          dataIndex: 'linea_productiva_1',
          key: 'linea_productiva_1',
          sorter: {
            compare: (a, b) => (a.linea_productiva_1 || '').localeCompare(b.linea_productiva_1 || ''),
            multiple: 3,
          },
          width: 150,
        },
        {
          title: 'Variedad establecida',
          width: 150,
          align: 'left',
          dataIndex: 'variedad_establecida',
          key: 'variedad_establecida'
        },
        {
          title: 'Línea Productiva 2',
          width: 150,
          align: 'left',
          dataIndex: 'linea_productiva_segundaria',
          key: 'linea_productiva_segundaria'
        },
        {
          title: 'Número de Hectáreas',
          dataIndex: 'num_hectareas',
          key: 'num_hectareas',
          width: 150,
          align: 'Center'
        },
        {
          title: 'Producción anual',
          dataIndex: 'produccion_anual',
          key: 'produccion_anual',
          width: 150,
          align: 'Center'
        },
        {
          title: 'Distancia Lugar del Proyecto a Cabecera Municipal - (Kilómetros)',
          dataIndex: 'distancia_cabecera_municipal',
          key: 'distancia_cabecera_municipal',
          width: 300,
          align: 'Center'
        },
        {
          title: 'Tiempo Lugar del Proyecto a Cabecera Municipal - (Minutos)',
          dataIndex: 'tiempo_cabecera_municipal',
          key: 'tiempo_cabecera_municipal',
          width: 250,
          align: 'Center'
        },
        {
          title: 'Medio de Transporte',
          dataIndex: 'medio_transporte',
          key: 'medio_transporte',
          width: 150,
          align: 'left'
        },
        {
          title: '¿Vive en el mismo lugar del Proyecto?',
          dataIndex: 'vive_mismo_lugar',
          key: 'vive_mismo_lugar',
          width: 200,
          align: 'Center'
        },
        {
          title: '¿Lugar de Vivienda?',
          dataIndex: 'lugar_vivienda',
          key: 'lugar_vivienda',
          width: 150,
          align: 'left'
        },
      ],
    },


    {
      title: 'DATOS DE CONTACTO DEL BENEFICIARIO',
      children: [
        {
          title: 'Dirección de Correspondencia',
          dataIndex: 'direccion_correspondencia',
          key: 'direccion_correspondencia',
          width: 150,
          align: 'left'
        },
        {
          title: 'Número Telefónico 1',
          dataIndex: 'numero_telefonico_1',
          key: 'numero_telefonico_1',
          width: 150,
          align: 'left'
        },
        {
          title: 'Número Telefónico 2',
          dataIndex: 'numero_telefonico_2',
          key: 'numero_telefonico_2',
          width: 150,
          align: 'left'
        },
        {
          title: 'Correo eléctronico',
          dataIndex: 'correo_electronico',
          key: 'correo_electronico',
          width: 100,
          align: 'left'
        },

      ]
    },


    {
      title: 'CONECTIVIDAD',
      children: [
        {
          title: 'Telefónica',
          dataIndex: 'telefonica',
          key: 'telefonica',
          width: 110,
          align: 'center'
        },
        {
          title: 'Internet',
          dataIndex: 'internet',
          key: 'internet',
          width: 100,
          align: 'center'

        },

      ]
    },

    {
      title: 'INFORMACIÓN BANCARIA',
      children: [
        {
          title: 'Tipo Establecimiento Bancario cercano a su Viviendaónica',
          dataIndex: 'establemcimiento_bancario',
          key: 'establemcimiento_bancario',
          width: 250,
          align: 'center'
        },
        {
          title: '¿Cuenta con Productos Financieros?',
          dataIndex: 'cuenta_con_productos_financieros',
          key: 'cuenta_con_productos_financieros',
          width: 200,
          align: 'center'
        },
        {
          title: 'Cuenta de Ahorro',
          dataIndex: 'cuenta_de_ahorro',
          key: 'cuenta_de_ahorro',
          width: 150,
          align: 'center'
        },
        {
          title: 'Tarjeta de Crédito',
          dataIndex: 'tarjeta_de_credito',
          key: 'tarjeta_de_credito',
          width: 150,
          align: 'center'
        },
        {
          title: 'Otros Créditos ',
          dataIndex: 'otros_creditos',
          key: 'otros_creditos',
          width: 150,
          align: 'center'
        },
        {
          title: 'Tipo de entidad',
          dataIndex: 'tipo_entidad',
          key: 'tipo_entidad',
          width: 150,
          align: 'left'
        },
        {
          title: '¿Ha recibido Capacitación en Educación Financiera?',
          dataIndex: 'recibio_capacitacion_educacion_financiera',
          key: 'recibio_capacitacion_educacion_financiera',
          width: 250,
          align: 'center'
        },
        {
          title: '¿Interesado en  Información de Inclusión Financiera?',
          dataIndex: 'interesado_informacion_inclusion_financiera',
          key: 'interesado_informacion_inclusion_financiera',
          width: 250,
          align: 'left'
        },
      ]
    },
    {
      title: 'INFORMACIÓN ORGANIZACIONAL',
      children: [
        {
          title: '¿Vinculado a algúna Organización?',
          dataIndex: 'vinculado_alguna_organizacion',
          key: 'vinculado_alguna_organizacion',
          width: 200,
          align: 'left'
        },
        {
          title: 'Tipo de Organización',
          dataIndex: 'tipo_organizacion',
          key: 'tipo_organizacion',
          width: 150,
          align: 'left'
        },
        {
          title: 'Nombre de la organización',
          dataIndex: 'organizacion',
          key: 'organizacion',
          sorter: {
            compare: (a, b) => (a.organizacion || '').localeCompare(b.organizacion || ''),
            multiple: 3,
          },
          width: 150,
        },
        {
          title: 'Es una organización de Mujeres?',
          dataIndex: 'org_mujeres',
          key: 'org_mujeres',
          width: 200,
          align: 'left'
        },
      ]
    },

    {
      title: 'VALIDACIÓN INTERNA ART',
      children: [
        {
          title: '¿Validado Por Registraduría?',
          dataIndex: 'validado_registraduria',
          key: 'validado_registraduria',
          width: 150,
          align: 'Center'
        },
        {
          title: '¿Validado Por Procuraduría?',
          dataIndex: 'validado_procuraduria',
          key: 'validado_procuraduria',
          width: 150,
          align: 'Center',
        },
        {
          title: '¿Validado Por Policía?',
          dataIndex: 'validado_policia',
          key: 'validado_policia',
          width: 150,
          align: 'Center',
        },
        {
          title: 'Mediadas correctivas',
          dataIndex: 'RNMC',
          key: 'RNMC',
          width: 150,
          align: 'left',
        },

        {
          title: '¿Validado contraloria?',
          dataIndex: 'validado_contraloria',
          key: 'validado_contraloria',
          width: 150,
          align: 'left',
        }
      ]
    },

    {
      title: 'Progreso',
      dataIndex: 'progress',
      key: 'progress',
      render: (progress) => (
        <Progress
          percent={progress}
          steps={5}
          strokeColor={[
            red[5], // 0% - 20%
            red[5], // 21% - 40%
            yellow[5], // 41% - 60%
            orange[4], // 61% - 80%
            green[6]  // 81% - 100%
          ]}
        />
      ),
    },
  ];

  if (loading) {
    return <div className="text-center"><Spin size="large" /></div>;
  }

  return (
    <div className={`container ${proyectoCodigo ? 'full-width' : ''}`}>
      <React.Fragment>
        {status === 404 || status === 500 ? (
          renderErrorMessage(status)
        ) : (
          <React.Fragment>
            <div className="row mb-2 d-flex align-items-center">
              <div className="col-md-8 linea_separador mb-2 d-flex align-items-center">
                <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                  <p>PROYECTO UTF <br /> COL 160 COL</p>
                </div>
                <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
                  <p>Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET</p>
                </div>
              </div>
              <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
                <h2 className="text-center mb-2">Listado de Participantes</h2>
                <div className="row mb-4">
                  <div className="col-md-12">
                    <div className="input-group shadow-sm">
                      {permissionExport && <ExportToExcelParticipantes data={filterParticipantes} />}
                      <Button
                        type="danger"
                        className="btn btn-primary"
                        onClick={clearAllFilters}
                        size="large"
                        icon={<ClearOutlined />}
                      >
                        Limpiar
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                    <div className="col-md-3 mb-3">
                      <div className="form-group">
                        <label htmlFor="filterInput">Número de Identificación:</label>
                        <Input
                          type="number"
                          value={numeroIdentificacion}
                          onChange={(e) => setNumeroIdentificacion(e.target.value)}
                          id="filterInput"
                          placeholder="Ingrese un número"
                          style={{ width: '100%' }}
                        />
                      </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label htmlFor="projectSelect">departamento:</label>
                          <Select
                            showSearch
                            value={selectedDepartamento}
                            placeholder="Selecciona un departamento"
                            optionFilterProp="children"
                            onChange={(value) => handleDepartamentoChange(value)}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            className="select-project"
                            style={{ width: '100%' }}
                          >
                            {listaDepartamentos.map((d) => (
                              <Option key={d.id_departamento} value={d.id_departamento}>
                                {d.departamento}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label htmlFor="projectSelect">Municipio:</label>
                          <Select
                            showSearch
                            placeholder="Selecciona un municipio"
                            value={selectedMunicipio}
                            optionFilterProp="children"
                            onChange={(value) => setSelectedMunicipio(value)}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            className="select-project"
                            style={{ width: '100%' }}
                          >
                            {listaMunicipiosFilter.map((m) => (
                              <Option key={m.id_municipio} value={m.id_municipio}>
                                {m.municipio}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>
                      <div className="col-md-3 mb-3">
                        <div className="form-group">
                          <label htmlFor="projectSelect">Proyecto:</label>
                          <Select
                            showSearch
                            placeholder="Selecciona un proyecto"
                            value={selectedProyecto}
                            optionFilterProp="children"
                            onChange={(value) => setSelectedproyecto(value)}
                            filterOption={(input, option) =>
                              option.children.toLowerCase().includes(input.toLowerCase())
                            }
                            className="select-project"
                            style={{ width: '100%' }}
                          >
                            {listaProyectos.map((proyecto) => (
                              <Option key={proyecto.proyecto_codigo} value={proyecto.proyecto_codigo}>
                                {`${proyecto.proyecto_codigo} - ${proyecto.nombre_proyecto}`}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      </div>


                    </div>

                    <div class="participant-count-container">
                      <h1 class="participant-count-title">
                        Total Participantes: <span class="participant-count-number">{filterParticipantes.length}</span>
                      </h1>
                    </div>


                  </div>
                </div>
              </div>


              <div className="col-md-12">
                {filterParticipantes.length > 0 ? (
                  viewMode === 'table' ? (
                    <Table
                      columns={columns}
                      dataSource={filterParticipantes}
                      rowKey='oid'
                      bordered
                      pagination={pagination}
                      onChange={handleTableChange}
                      sortDirections={['ascend', 'descend']}
                      sortOrder={sortOrder}
                      onSorterChange={setSortOrder}
                      sortField={sortField}
                      locale={noDataConfig}
                      loading={loading}
                      filteredInfo={filteredInfo}
                      scroll={{ y: 500, x: 'max-content' }}
                    />
                  ) : (
                    <p className="text-center">No hay participantes para mostrar.</p>
                  )
                ) : (
                  <div className="col-md-12">
                    <Alert
                      message="Sin resultados"
                      description="No hay participantes para mostrar."
                      type="info"
                      showIcon
                      className="mt-4"
                    />
                  </div>
                )}
              </div>
            </div>

          </React.Fragment>
        )}


      </React.Fragment>
    </div>
  );
};

export default Participantes;