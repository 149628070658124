import React, { useEffect, useState } from "react";
import { Table, Button, Row, Col, Tooltip, Modal, message  } from "antd";
import { getAcompOrg, upsertAcompOrg } from "../../services/acompaOrg/acompOrgService";
import { ArrowLeftOutlined, FolderOpenOutlined } from '@ant-design/icons';
import moment from "moment";
import FusionCharts from 'fusioncharts';
import Chart from 'fusioncharts/fusioncharts.charts';
import FusionChartsModule from 'fusioncharts/fusioncharts.charts';
import FusionChartsTheme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import { useNavigate } from 'react-router-dom';
import FileManagerPOA2 from './FileAcompanamiento';
import EditableCell from './EditableCellAcomp';


FusionChartsModule(FusionCharts);
FusionChartsTheme(FusionCharts);
Chart(FusionCharts);

const AcompanamientoOrg = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [selectedMonth,] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filteredData, setFilteredData] = useState([]);


  const mesesEnEspañol = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAcompOrg();
        setData(result);
      } catch (error) {
        console.error("Error al cargar los datos:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const transformedData = data.flatMap((org, orgIndex) => {
      const totalPorRegistro = org.fechas.reduce((acumulador, item) => {
        const acompanamiento = item.archivos.Memoria_Acompanamiento || 0;
        const capacitacion = item.archivos.Memoria_Capacitacion || 0;
        return acumulador + acompanamiento + capacitacion;
      }, 0);
      const sortedFechas = org.fechas.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
      return sortedFechas.map((item, index) => {
        const isFirstRow = index === 0;
        const rowSpanValue = isFirstRow ? sortedFechas.length : 0;
        const monthNumber = moment(item.fecha).month(); 
        const year = moment(item.fecha).year();
        const month = mesesEnEspañol[monthNumber]; 
        const currentMonthYear = `${month} ${year}`;
        const previousMonthYear =
          index > 0
            ? `${mesesEnEspañol[moment(sortedFechas[index - 1].fecha).month()]} ${moment(sortedFechas[index - 1].fecha).year()}`
            : null;
        const isFirstMonthRow = index === 0 || currentMonthYear !== previousMonthYear;
        const monthRowSpanValue = isFirstMonthRow
          ? sortedFechas.filter(
            (f) => {
              const fMonth = mesesEnEspañol[moment(f.fecha).month()];
              const fYear = moment(f.fecha).year();
              return `${fMonth} ${fYear}` === currentMonthYear;
            }
          ).length
          : 0;
        if (selectedMonth && month !== selectedMonth) return null;
        const tematica = item.datos && item.datos.length > 0 ? item.datos[0].tematica : "";
        const plan_acompa_obs = item.datos && item.datos.length > 0 ? item.datos[0].plan_acompa_obs : "";
        const plan_acompa_num = item.datos && item.datos.length > 0 ? item.datos[0].plan_acompa_num : "";
        const reunion_socializa_obs = item.datos && item.datos.length > 0 ? item.datos[0].reunion_socializa_obs : "";
        const reunion_socializa_num = item.datos && item.datos.length > 0 ? item.datos[0].reunion_socializa_num : "";
        const plan_compras_obs = item.datos && item.datos.length > 0 ? item.datos[0].plan_compras_obs : "";
        const plan_compras_num = item.datos && item.datos.length > 0 ? item.datos[0].plan_compras_num : "";
        const oid = item.datos && item.datos.length > 0 ? item.datos[0].oid : "";
        const fechaORG = item.datos && item.datos.length > 0 ? item.datos[0].fecha : "";
        return {
          key: `${orgIndex}-${index}`,
          oid_acomorg: org.oid_acomorg,
          proyecto: org.codigo_proyecto,
          subregion: org.sub_region,
          departamento: org.departamento,
          municipio: org.municipio,
          zona: org.zona,
          linea_p: org.linea_p,
          organizacion: isFirstRow ? org.sigla_org : "",
          mes: isFirstMonthRow ? currentMonthYear : "", 
          dia: moment(item.fecha).format("D"),
          rowSpan: rowSpanValue,
          monthRowSpan: monthRowSpanValue,
          acompanamiento: item.archivos.Memoria_Acompanamiento,
          capacitacion: item.archivos.Memoria_Capacitacion,
          totalPorRegistro: isFirstRow ? totalPorRegistro : "",
          tematica: tematica,
          plan_acompa_obs: plan_acompa_obs,
          plan_acompa_num: plan_acompa_num,
          reunion_socializa_obs: reunion_socializa_obs,
          reunion_socializa_num: reunion_socializa_num,
          plan_compras_obs: plan_compras_obs,
          plan_compras_num: plan_compras_num,
          oid: oid,
          fechaORG: fechaORG,
        };
      }).filter((item) => item !== null); 
    });
    setFilteredData(transformedData);
  }, [data, selectedMonth]); 

  /*const filteredData = data.flatMap((org, orgIndex) => {
    const totalPorRegistro = org.fechas.reduce((acumulador, item) => {
      const acompanamiento = item.archivos.Memoria_Acompanamiento || 0;
      const capacitacion = item.archivos.Memoria_Capacitacion || 0;
      return acumulador + acompanamiento + capacitacion;
    }, 0);
    const sortedFechas = org.fechas.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
    return sortedFechas.map((item, index) => {
      const isFirstRow = index === 0;
      const rowSpanValue = isFirstRow ? sortedFechas.length : 0;
      const monthNumber = moment(item.fecha).month(); 
      const year = moment(item.fecha).year();
      const month = mesesEnEspañol[monthNumber]; 
      const currentMonthYear = `${month} ${year}`;
      const previousMonthYear =
        index > 0
          ? `${mesesEnEspañol[moment(sortedFechas[index - 1].fecha).month()]} ${moment(sortedFechas[index - 1].fecha).year()}`
          : null;
      const isFirstMonthRow = index === 0 || currentMonthYear !== previousMonthYear;
      const monthRowSpanValue = isFirstMonthRow
        ? sortedFechas.filter(
          (f) => {
            const fMonth = mesesEnEspañol[moment(f.fecha).month()];
            const fYear = moment(f.fecha).year();
            return `${fMonth} ${fYear}` === currentMonthYear;
          }
        ).length
        : 0;
      if (selectedMonth && month !== selectedMonth) return null;
      const tematica = item.datos && item.datos.length > 0 ? item.datos[0].tematica : "";
      const plan_acompa_obs = item.datos && item.datos.length > 0 ? item.datos[0].plan_acompa_obs : "";
      const plan_acompa_num = item.datos && item.datos.length > 0 ? item.datos[0].plan_acompa_num : "";
      const reunion_socializa_obs = item.datos && item.datos.length > 0 ? item.datos[0].reunion_socializa_obs : "";
      const reunion_socializa_num = item.datos && item.datos.length > 0 ? item.datos[0].reunion_socializa_num : "";
      const plan_compras_obs = item.datos && item.datos.length > 0 ? item.datos[0].plan_compras_obs : "";
      const plan_compras_num = item.datos && item.datos.length > 0 ? item.datos[0].plan_compras_num : "";
      const oid = item.datos && item.datos.length > 0 ? item.datos[0].oid : "";
      const fechaORG = item.datos && item.datos.length > 0 ? item.datos[0].fecha : "";
      return {
        key: `${orgIndex}-${index}`,
        oid_acomorg: org.oid_acomorg,
        proyecto: org.codigo_proyecto,
        subregion: org.sub_region,
        departamento: org.departamento,
        municipio: org.municipio,
        zona: org.zona,
        linea_p: org.linea_p,
        organizacion: isFirstRow ? org.sigla_org : "",
        mes: isFirstMonthRow ? currentMonthYear : "", 
        dia: moment(item.fecha).format("D"),
        rowSpan: rowSpanValue,
        monthRowSpan: monthRowSpanValue,
        acompanamiento: item.archivos.Memoria_Acompanamiento,
        capacitacion: item.archivos.Memoria_Capacitacion,
        totalPorRegistro: isFirstRow ? totalPorRegistro : "",
        tematica: tematica,
        plan_acompa_obs: plan_acompa_obs,
        plan_acompa_num: plan_acompa_num,
        reunion_socializa_obs: reunion_socializa_obs,
        reunion_socializa_num: reunion_socializa_num,
        plan_compras_obs: plan_compras_obs,
        plan_compras_num: plan_compras_num,
        oid: oid,
        fechaORG: fechaORG,
      };
    }).filter((item) => item !== null); 
  });*/

  const convertirFecha = (fecha) => {
    const meses = {
      enero: '01',
      febrero: '02',
      marzo: '03',
      abril: '04',
      mayo: '05',
      junio: '06',
      julio: '07',
      agosto: '08',
      septiembre: '09',
      octubre: '10',
      noviembre: '11',
      diciembre: '12'
    };
    const [mes, anio] = fecha.toLowerCase().split(' ');
    const mesNumero = meses[mes];
    const anioNumero = anio;
    return `${anioNumero}-${mesNumero}`;
  };

  const handleSave = async (updatedRecord) => {
    const updatedData = {
      oid: updatedRecord.oid,
      tematica: updatedRecord.tematica,
      plan_acompa_obs: updatedRecord.plan_acompa_obs,
      plan_acompa_num: updatedRecord.plan_acompa_num,
      plan_compras_obs: updatedRecord.plan_compras_obs,
      plan_compras_num: updatedRecord.plan_compras_num,
      reunion_socializa_obs: updatedRecord.reunion_socializa_obs,
      reunion_socializa_num: updatedRecord.reunion_socializa_num,
      oid_acomorg: updatedRecord.oid_acomorg,
      fecha: convertirFecha(updatedRecord.mes),
    };
    try {
      await upsertAcompOrg(updatedData);
      const newData = [...filteredData];
      const index = newData.findIndex((item) => item.key === updatedRecord.key);
      if (index > -1) {
        newData[index] = { ...newData[index], ...updatedRecord };
        setFilteredData(newData);
      }
      message.success('Registro guardado exitosamente');
    } catch (error) {
      console.error('Error al guardar el registro:', error);
      message.error('Error al guardar el registro');
    }
  };

  const columns = [
    {
      title: "Proyecto",
      dataIndex: "proyecto",
      key: "proyecto",
      width: 100,
      align: 'center',
      render: (_, record) => record.proyecto,
      onCell: (record, index) => ({
        rowSpan: record.rowSpan,
      }),
    },
    {
      title: "Sub-Región",
      dataIndex: "subregion",
      key: "subregion",
      width: 100,
      align: 'center',
      render: (_, record) => {
        const capitalizeWords = (text) =>
          text
            .toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase());
        return capitalizeWords(record.subregion);
      },
      onCell: (record, index) => ({
        rowSpan: record.rowSpan,
        style: {
          whiteSpace: 'normal',
          wordWrap: 'break-word',
        },
      }),
    },

    {
      title: "Departamento",
      dataIndex: "departamento",
      key: "departamento",
      width: 100,
      align: 'center',
      render: (_, record) => record.departamento,
      onCell: (record, index) => ({
        rowSpan: record.rowSpan,
      }),
    },
    {
      title: "Municipio",
      dataIndex: "municipio",
      key: "municipio",
      width: 100,
      align: 'center',
      render: (_, record) => record.municipio,
      onCell: (record, index) => ({
        rowSpan: record.rowSpan,
      }),
    },
    {
      title: "Zona",
      dataIndex: "zona",
      key: "zona",
      width: 100,
      align: 'center',
      render: (_, record) => record.zona,
      onCell: (record, index) => ({
        rowSpan: record.rowSpan,
      }),
    },
    {
      title: "Linea productiva",
      dataIndex: "linea_p",
      key: "linea_p",
      width: 100,
      align: 'center',
      render: (_, record) => record.linea_p,
      onCell: (record, index) => ({
        rowSpan: record.rowSpan,
      }),
    },
    {
      title: "Organización",
      dataIndex: "organizacion",
      key: "organizacion",
      width: 100,
      align: 'center',
      render: (_, record) => record.organizacion,
      onCell: (record, index) => ({
        rowSpan: record.rowSpan,
      }),
    },
    {
      title: "Mes / Año",
      dataIndex: "mes",
      key: "mes",
      width: 100,
      align: 'center',
      onCell: (record) => ({
        rowSpan: record.monthRowSpan,
      }),
      render: (_, record) => record.mes,
    },
    {
      title: "Día",
      dataIndex: "dia",
      key: "dia",
      width: 100,
      align: 'center',
    },
    {
      title: "Acompañamiento Técnico",
      dataIndex: "acompanamiento",
      key: "acompanamiento",
      width: 200,
      align: 'center',

    },
    {
      title: "Capacitación",
      dataIndex: "capacitacion",
      key: "capacitacion",
      width: 200,
      align: 'center',
    },
    {
      title: "Total",
      dataIndex: "totalPorRegistro",
      key: "totalPorRegistro",
      width: 200,
      align: 'center',
      render: (_, record) => record.totalPorRegistro,
      onCell: (record, index) => ({
        rowSpan: record.rowSpan,
      }),
    },
    {
      title: "Temáticas",
      dataIndex: "tematica",
      key: "tematica",
      render: (_, record) => (
        <Tooltip title={record.tematica}>
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100px',
            }}
          >
            {record.tematica}
          </div>
        </Tooltip>
      ),
      onCell: (record) => ({
        record,
        editable: true,
        title: "tematica",
        text: record.tematica,
        handleSave,
        rowSpan: record.monthRowSpan,
      }),
    },
    {
      title: "Plan de acompañamiento",
      dataIndex: "plan_acompa_obs",
      key: "plan_acompa_obs",
      render: (_, record) => (
        <Tooltip title={record.plan_acompa_obs}>
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100px',
            }}
          >
            {record.plan_acompa_obs}
          </div>
        </Tooltip>
      ),
      onCell: (record) => ({
        record,
        editable: true,
        title: "plan_acompa_obs",
        text: record.plan_acompa_obs,
        handleSave,
        rowSpan: record.monthRowSpan,
      }),
    },
    {
      title: "Número de planes de acompañamiento",
      dataIndex: "plan_acompa_num",
      key: "plan_acompa_num",
      render: (_, record) => (
        <Tooltip title={record.plan_acompa_num}>
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100px',
            }}
          >
            {record.plan_acompa_num}
          </div>
        </Tooltip>
      ),
      onCell: (record) => ({
        record,
        editable: true,
        title: "plan_acompa_num",
        text: record.plan_acompa_num,
        handleSave,
        rowSpan: record.monthRowSpan,
      }),
    }, {
      title: "Reunión de socialización",
      dataIndex: "reunion_socializa_obs",
      key: "reunion_socializa_obs",
      render: (_, record) => (
        <Tooltip title={record.reunion_socializa_obs}>
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100px',
            }}
          >
            {record.reunion_socializa_obs}
          </div>
        </Tooltip>
      ),
      onCell: (record) => ({
        record,
        editable: true,
        title: "reunion_socializa_obs",
        text: record.reunion_socializa_obs,
        handleSave,
        rowSpan: record.monthRowSpan,
      }),
    }, {
      title: "Número de reuniones de socialización",
      dataIndex: "reunion_socializa_num",
      key: "reunion_socializa_num",
      render: (_, record) => (
        <Tooltip title={record.reunion_socializa_num}>
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100px',
            }}
          >
            {record.reunion_socializa_num}
          </div>
        </Tooltip>
      ),
      onCell: (record) => ({
        record,
        editable: true,
        title: "reunion_socializa_num",
        text: record.reunion_socializa_num,
        handleSave,
        rowSpan: record.monthRowSpan,
      }),
    },
    {
      title: "Plan de compras",
      dataIndex: "plan_compras_obs",
      key: "plan_compras_obs",
      render: (_, record) => (
        <Tooltip title={record.plan_compras_obs}>
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100px',
            }}
          >
            {record.plan_compras_obs}
          </div>
        </Tooltip>
      ),
      onCell: (record) => ({
        record,
        editable: true,
        title: "plan_compras_obs",
        text: record.plan_compras_obs,
        handleSave,
        rowSpan: record.monthRowSpan,
      }),
    },
    {
      title: "Número de planes de compras",
      dataIndex: "plan_compras_num",
      key: "plan_compras_num",
      render: (_, record) => (
        <Tooltip title={record.plan_compras_num}>
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100px',
            }}
          >
            {record.plan_compras_num}
          </div>
        </Tooltip>
      ),
      onCell: (record) => ({
        record,
        editable: true,
        title: "plan_compras_num",
        text: record.plan_compras_num, // Pasa el valor de 'tematica' para que se edite
        handleSave, // Función para guardar los cambios
        rowSpan: record.monthRowSpan, // Incluye el rowSpan aquí
      }),
    },
  ];

  const handleCardClick = () => {
    navigate(`/menu-curso-fortalecimiento`);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  const handleNoInfoClick = () => {
    setIsModalVisible(true);
  };

  return (
    <div className="container">
      <div className="row mb-2 d-flex align-items-center">
        <div className="col-md-7 linea_separador mb-2 d-flex align-items-center">
          <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
            <p>PROYECTO UTF <br /> COL 160 COL</p>
          </div>
          <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
            <p>
              Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades
              institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET
            </p>
          </div>
        </div>
        <div className="col-md-5 d-flex justify-content-center align-items-center flex-column">
          <h2 className="text-center mb-5">Acompañamiento Organizaciones</h2>
          <Row gutter={5}>
            <Col>
              <Button
                onClick={() => handleCardClick()}
                style={{ width: 125 }}
                icon={<ArrowLeftOutlined />}
              >
                Regresar
              </Button>
            </Col>
            <Col>
              <Button
                style={{ width: 125 }}
                icon={<FolderOpenOutlined />}
                onClick={() => handleNoInfoClick()}
              >
                Soportes
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div>
        <div className="tabla-container" style={{ maxHeight: '700px', overflowY: 'auto' }}>
          <Table
            columns={columns.map((col) => {
              if (!col.editable) {
                return col;
              }
              return {
                ...col,
                onCell: (record) => ({
                  record,
                  editable: col.editable,
                  value: record[col.dataIndex], 
                  title: col.dataIndex,
                  handleSave,
                }),
              };
            })}
            dataSource={filteredData}
            bordered
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            pagination={false}
            summary={() => {
              const totalAcompanamiento = filteredData.reduce(
                (sum, row) => sum + (row.acompanamiento || 0),
                0
              );
              const totalCapacitacion = filteredData.reduce(
                (sum, row) => sum + (row.capacitacion || 0),
                0
              );
              const totalPorRegistro = filteredData.reduce(
                (sum, row) => sum + (row.totalPorRegistro || 0),
                0
              );
              return (
                <Table.Summary.Row style={{ backgroundColor: '#042956', color: '#ffffff' }}>
                  <Table.Summary.Cell index={0} colSpan={9} align="center">
                    <b>Total Registros</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="center">
                    <b>{totalAcompanamiento}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="center">
                    <b>{totalCapacitacion}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center">
                    <b>{totalPorRegistro}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={4} colSpan={9} align="center">
                    <b></b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              );
            }}
          />
        </div>
        <div className="d-flex justify-content-center">
          <Modal
            title={<span className="custom-modal-title">Repositorio de Acompañamiento Organizacional</span>}
            open={isModalVisible}
            onCancel={hideModal}
            footer={null}
            width="95%"
            style={{ top: 20 }}
          >
            <FileManagerPOA2 />
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AcompanamientoOrg;
