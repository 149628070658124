import React, { useEffect, useState } from "react";
import { Table, Layout, Descriptions, Collapse, Typography, message, Tooltip, Modal, Button, DatePicker } from 'antd';
import { getHeader, getPOA, updateAvance, updateFecha } from '../../services/plaOperativo/headerPlanOperativoServices';
import { LinkOutlined, InfoCircleOutlined, MenuOutlined, MoreOutlined, ArrowLeftOutlined, WarningOutlined } from '@ant-design/icons';
import { fetchPoaData } from '../../services/poa/get_poa';
import FileManagerPOA2 from './FileManagerPP';
import FileManagerPOAN from './FileManagerPOAN';
import EditableCell from './EditableCell';
import './PoaPlanOperativoTable.css';
import moment from 'moment';
import dayjs from 'dayjs';

const { Panel } = Collapse;
const { Title } = Typography;

const PoaPlanOperativoTB = ({ selectedFase, selectedProject, regresarAnterior, cerrarModal }) => {
  const [headerPO, setHeaderPO] = useState({});
  const [loading, setLoading] = useState(true);
  const [actividades, setActividades] = useState([]);
  const [error, setError] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [actividadSelect, setActividadSelect] = useState(null);
  const [openModal1, setOpenModal1] = useState(false);
  const [itemParams, setItemParams] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [mesInicial2024, setMesInicial2024] = useState('');
  const [meses2024, setMeses2024] = useState([]);
  const [mesInicial2025, setMesInicial2025] = useState('');
  const [meses2025, setMeses2025] = useState([]);
  const [tareasSubtareas, setTareasSubtareas] = useState([]);
  const [columnasDinamicas2024, setColumnasDinamicas2024] = useState([]);
  const [columnasDinamicas2025, setColumnasDinamicas2025] = useState([]);
  const [editingDate, setEditingDate] = useState(false);

  const generarColumnasDinamicas = (meses, mesInicial, ano) => {
    const mesInicialIndex = meses.indexOf(mesInicial);
    if (mesInicialIndex === -1) return [];
    return meses
      .slice(mesInicialIndex) 
      .map((mes) => [
        {
          title: mes,
          dataIndex: `${mes.toLowerCase()}${ano}`,
          key: `key${capitalizarPrimeraLetra(mes)}${ano}`,
          width: 100,
          align: 'center',
          editable: true,
          render: (text, record) => {
            const value = !isNaN(parseFloat(text)) ? Math.floor(parseFloat(text)) : text; 
            return (
              <div
                style={{
                  backgroundColor: !isNaN(parseFloat(text)) && parseFloat(text) >= 1 ? '#96BEEF' : 'transparent',
                  color: !isNaN(parseFloat(text)) && parseFloat(text) >= 1 ? '#FFFFFF' : 'inherit',
                }}
              >
                {value}
              </div>
            );
          },
          onCell: (record) => ({
            record,
            editable: true,
            title: `${mes.toLowerCase()}${ano}`,
            handleSave: (value) =>
              handleSave(value, record[`key${capitalizarPrimeraLetra(mes)}${ano}`], `${mes.toLowerCase()}${ano}`),
          }),
        },
        {
          title: '% Avance',
          dataIndex: `avance${capitalizarPrimeraLetra(mes)}${ano}`,
          key: `avance${capitalizarPrimeraLetra(mes)}${ano}`,
          width: 100,
          align: 'center',
          render: (text) => {
            const progreso = parseFloat(text);
            const { color, backgroundColor } = getAvanceStyle(progreso);
            return <div style={{ color, backgroundColor }}>{text}</div>;
          },
          onCell: (record, rowIndex) => ({ rowSpan: rowIndex % 2 === 0 ? 2 : 0, })
        },
      ])
      .flat();
  };

  useEffect(() => {
    if (meses2024 && mesInicial2024) {
      const columnas2024 = generarColumnasDinamicas(meses2024, mesInicial2024, '2024');
      const columnas2025 = generarColumnasDinamicas(meses2025, mesInicial2025, '2025');
      setColumnasDinamicas2024(columnas2024);
      setColumnasDinamicas2025(columnas2025);
    }
  }, [meses2024, mesInicial2024]);

  const generarMeses = (mesInicio, mesFin) => {
    const meses = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    const indiceInicio = meses.indexOf(mesInicio);
    const indiceFin = meses.indexOf(mesFin);
    if (indiceInicio === -1 || indiceFin === -1) {
      return [];
    }
    return meses.slice(indiceInicio, indiceFin + 1);
  };

  const actualizarMeses = (data) => {
    let encontrado2024 = false;
    let encontrado2025 = false;
    data.forEach(actividad => {
      if (encontrado2024 && encontrado2025) return;
      actividad.children.forEach(tarea => {
        if (encontrado2024 && encontrado2025) return;
        tarea.children.forEach(item => {
          if (encontrado2024 && encontrado2025) return;
          item.cronogramaconfig.forEach(config => {
            if (config.anio === '2024' && !encontrado2024) {
              setMesInicial2024(config.mes_inicio);
              setMeses2024(generarMeses(config.mes_inicio, config.mes_final));
              encontrado2024 = true;
            } else if (config.anio === '2025' && !encontrado2025) {
              setMesInicial2025(config.mes_inicio);
              setMeses2025(generarMeses(config.mes_inicio, config.mes_final));
              encontrado2025 = true;
            }
          });
        });
      });
    });
  };

  const formatDate = (date) => {
    if (!date) return "Sin fecha";
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    try {
      return new Date(date).toLocaleDateString('es-ES', options);
    } catch {
      return "Formato inválido"; 
    }
  };

  const calcularAvance = (avanceP, avanceE) => {
    if (avanceP && avanceE) {
      if (avanceP === 0 || avanceP === '-' || avanceE === 0) return '0%';
      const sumaTotalP = parseFloat(avanceP);
      const sumaTotalE = parseFloat(avanceE);
      if (sumaTotalP === 0) return '0%';
      return `${((sumaTotalE / sumaTotalP) * 100).toFixed(0)}%`;
    }
    return '0%';
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const { data } = await getHeader(selectedProject.oid);
        setHeaderPO(data?.encabezado[0]);
        setError(false);
      } catch (error) {
        setError(true);
        console.error("Error fetching projects:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProjects();
  }, [selectedProject.oid]);

  useEffect(() => {
    const fetchPOA = async () => {
      try {
        setActividades([]);
        const { data } = await getPOA(selectedFase.oid, selectedProject.oid);
        console.log(data);
        if (Array.isArray(data)) {
          actualizarMeses(data);
          setActividades(data);
          setTareasSubtareas(mapTareasSubtareas(data));
        } else {
          console.error("Los datos recibidos no son un array:", data);
          setActividades([]);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching POA:", error);
        setActividades([]);
        setLoading(false);
      }
    };

    fetchPOA();
  }, [selectedFase.oid, selectedProject.oid]);

  const mapTareasSubtareas = (actividades) => {
    return actividades.flatMap((actividad) => {
      if (actividad.children && Array.isArray(actividad.children)) {
        return actividad.children.flatMap((tarea) =>
          tarea.children ? tarea.children.map((subtarea) => {
            const rowData = {
              fechaFinReal: formatDate(subtarea.medios_verificacion[0]?.fecha_fin_real),
              medioVerificacion: subtarea.medios_verificacion[0]?.medio_verificacion || '-',
              observacion: subtarea.medios_verificacion[0]?.observacion || '-',
              ruta: subtarea.medios_verificacion[0]?.ruta || '-',
            };
            const cronogramasP = subtarea.cronogramasP || [];
            const cronogramasE = subtarea.cronogramasE || [];
            const getAvance = (mes, cronogramas, ano) => {
              const cronograma = cronogramas.find(cronograma => cronograma.mes === mes && cronograma.ano === ano);
              return cronograma ? cronograma.avance : '-';
            };
            const sumarAvances = (datos) => {
              const totalAvance = datos.reduce((sum, cronograma) => {
                const avance = parseFloat(cronograma.avance);
                return sum + (isNaN(avance) ? 0 : avance);
              }, 0);
              return totalAvance;
            };
            const sumaP2024_2025 = sumarAvances(cronogramasP);
            const sumaE2024_2025 = sumarAvances(cronogramasE);
            const avanceAcu = sumaP2024_2025 !== 0 ? ((sumaE2024_2025 / sumaP2024_2025) * 100).toFixed(0) + '%' : '0%';
            const dataTable = [
              {
                key: `subtarea-${subtarea.id}-1`,
                proyecto: selectedProject.codigo,
                codActividad: actividad.codigo_actividad,
                codTarea: tarea.codigo_tarea,
                codIndicador: subtarea.id,
                codMV: subtarea.medio_verificacion_id,
                tarea: `${tarea.codigo_tarea} - ${tarea.descripcion}`,
                indicador: subtarea.indicador,
                meta: subtarea.meta,
                fechaInicio: formatDate(subtarea.fecha_inicio),
                fechaFin: formatDate(subtarea.fecha_fin),
                pe: 'P',
                ene2024: getAvance('ENE', cronogramasP, '2024'),
                keyEne2024: getIdByMonthAndYear('ENE', '2024', cronogramasP, subtarea.id),
                feb2024: getAvance('FEB', cronogramasP, '2024'),
                keyFeb2024: getIdByMonthAndYear('FEB', '2024', cronogramasP, subtarea.id),
                mar2024: getAvance('MAR', cronogramasP, '2024'),
                keyMar2024: getIdByMonthAndYear('MAR', '2024', cronogramasP, subtarea.id),
                abr2024: getAvance('ABR', cronogramasP, '2024'),
                keyAbr2024: getIdByMonthAndYear('ABR', '2024', cronogramasP, subtarea.id),
                may2024: getAvance('MAY', cronogramasP, '2024'),
                keyMay2024: getIdByMonthAndYear('MAY', '2024', cronogramasP, subtarea.id),
                jun2024: getAvance('JUN', cronogramasP, '2024'),
                keyJun2024: getIdByMonthAndYear('JUN', '2024', cronogramasP, subtarea.id),
                jul2024: getAvance('JUL', cronogramasP, '2024'),
                keyJul2024: getIdByMonthAndYear('JUL', '2024', cronogramasP, subtarea.id),
                ago2024: getAvance('AGO', cronogramasP, '2024'),
                keyAgo2024: getIdByMonthAndYear('AGO', '2024', cronogramasP, subtarea.id),
                sep2024: getAvance('SEP', cronogramasP, '2024'),
                keySep2024: getIdByMonthAndYear('SEP', '2024', cronogramasP, subtarea.id),
                oct2024: getAvance('OCT', cronogramasP, '2024'),
                keyOct2024: getIdByMonthAndYear('OCT', '2024', cronogramasP, subtarea.id),
                nov2024: getAvance('NOV', cronogramasP, '2024'),
                keyNov2024: getIdByMonthAndYear('NOV', '2024', cronogramasP, subtarea.id),
                dic2024: getAvance('DIC', cronogramasP, '2024'),
                keyDic2024: getIdByMonthAndYear('DIC', '2024', cronogramasP, subtarea.id),
               
                ene2025: getAvance('ENE', cronogramasP, '2025'),
                keyEne2025: getIdByMonthAndYear('ENE', '2025', cronogramasP, subtarea.id),
                feb2025: getAvance('FEB', cronogramasP, '2025'),
                keyFeb2025: getIdByMonthAndYear('FEB', '2025', cronogramasP, subtarea.id),
                mar2025: getAvance('MAR', cronogramasP, '2025'),
                keyMar2025: getIdByMonthAndYear('MAR', '2025', cronogramasP, subtarea.id),
                abr2025: getAvance('ABR', cronogramasP, '2025'),
                keyAbr2025: getIdByMonthAndYear('ABR', '2025', cronogramasP, subtarea.id),
                may2025: getAvance('MAY', cronogramasP, '2025'),
                keyMay2025: getIdByMonthAndYear('MAY', '2025', cronogramasP, subtarea.id),
                jun2025: getAvance('JUN', cronogramasP, '2025'),
                keyJun2025: getIdByMonthAndYear('JUN', '2025', cronogramasP, subtarea.id),
                jul2025: getAvance('JUL', cronogramasP, '2025'),
                keyJul2025: getIdByMonthAndYear('JUL', '2025', cronogramasP, subtarea.id),
                ago2025: getAvance('AGO', cronogramasP, '2025'),
                keyAgo2025: getIdByMonthAndYear('AGO', '2025', cronogramasP, subtarea.id),
                sep2025: getAvance('SEP', cronogramasP, '2025'),
                keySep2025: getIdByMonthAndYear('SEP', '2025', cronogramasP, subtarea.id),
                oct2025: getAvance('OCT', cronogramasP, '2025'),
                keyOct2025: getIdByMonthAndYear('OCT', '2025', cronogramasP, subtarea.id),
                nov2025: getAvance('NOV', cronogramasP, '2025'),
                keyNov2025: getIdByMonthAndYear('NOV', '2025', cronogramasP, subtarea.id),
                dic2025: getAvance('DIC', cronogramasP, '2025'),
                keyDic2025: getIdByMonthAndYear('DIC', '2025', cronogramasP, subtarea.id),
                

                metaAcu: sumaP2024_2025,
                avanceAcu: avanceAcu,
                fechaFinReal: formatDate(subtarea.fecha_f_real),
                medioVerificacion: rowData.medioVerificacion,
                observacion: rowData.observacion,
                ruta: subtarea.ruta,
                actividad: actividad.descripcion,
              },
              {
                key: `subtarea-${subtarea.id}-2`,
                proyecto: '',
                codIndicador: '',
                codActividad: '',
                codTarea: '',
                codMV: '',
                tarea: '',
                indicador: '',
                meta: '',
                fechaInicio: '',
                fechaFin: '',
                pe: 'E',
                ene2024: getAvance('ENE', cronogramasE, '2024'),
                keyEne2024: getIdByMonthAndYear('ENE', '2024', cronogramasE, subtarea.id),
                feb2024: getAvance('FEB', cronogramasE, '2024'),
                keyFeb2024: getIdByMonthAndYear('FEB', '2024', cronogramasE, subtarea.id),
                mar2024: getAvance('MAR', cronogramasE, '2024'),
                keyMar2024: getIdByMonthAndYear('MAR', '2024', cronogramasE, subtarea.id),
                abr2024: getAvance('ABR', cronogramasE, '2024'),
                keyAbr2024: getIdByMonthAndYear('ABR', '2024', cronogramasE, subtarea.id),
                may2024: getAvance('MAY', cronogramasE, '2024'),
                keyMay2024: getIdByMonthAndYear('MAY', '2024', cronogramasE, subtarea.id),
                jun2024: getAvance('JUN', cronogramasE, '2024'),
                keyJun2024: getIdByMonthAndYear('JUN', '2024', cronogramasE, subtarea.id),
                jul2024: getAvance('JUL', cronogramasE, '2024'),
                keyJul2024: getIdByMonthAndYear('JUL', '2024', cronogramasE, subtarea.id),
                ago2024: getAvance('AGO', cronogramasE, '2024'),
                keyAgo2024: getIdByMonthAndYear('AGO', '2024', cronogramasE, subtarea.id),
                sep2024: getAvance('SEP', cronogramasE, '2024'),
                keySep2024: getIdByMonthAndYear('SEP', '2024', cronogramasE, subtarea.id),
                oct2024: getAvance('OCT', cronogramasE, '2024'),
                keyOct2024: getIdByMonthAndYear('OCT', '2024', cronogramasE, subtarea.id),
                nov2024: getAvance('NOV', cronogramasE, '2024'),
                keyNov2024: getIdByMonthAndYear('NOV', '2024', cronogramasE, subtarea.id),
                dic2024: getAvance('DIC', cronogramasE, '2024'),
                keyDic2024: getIdByMonthAndYear('DIC', '2024', cronogramasE, subtarea.id),

                ene2025: getAvance('ENE', cronogramasE, '2025'),
                keyEne2025: getIdByMonthAndYear('ENE', '2025', cronogramasE, subtarea.id),
                feb2025: getAvance('FEB', cronogramasE, '2025'),
                keyFeb2025: getIdByMonthAndYear('FEB', '2025', cronogramasE, subtarea.id),
                mar2025: getAvance('MAR', cronogramasE, '2025'),
                keyMar2025: getIdByMonthAndYear('MAR', '2025', cronogramasE, subtarea.id),
                abr2025: getAvance('ABR', cronogramasE, '2025'),
                keyAbr2025: getIdByMonthAndYear('ABR', '2025', cronogramasE, subtarea.id),
                may2025: getAvance('MAY', cronogramasE, '2025'),
                keyMay2025: getIdByMonthAndYear('MAY', '2025', cronogramasE, subtarea.id),
                jun2025: getAvance('JUN', cronogramasE, '2025'),
                keyJun2025: getIdByMonthAndYear('JUN', '2025', cronogramasE, subtarea.id),
                jul2025: getAvance('JUL', cronogramasE, '2025'),
                keyJul2025: getIdByMonthAndYear('JUL', '2025', cronogramasE, subtarea.id),
                ago2025: getAvance('AGO', cronogramasE, '2025'),
                keyAgo2025: getIdByMonthAndYear('AGO', '2025', cronogramasE, subtarea.id),
                sep2025: getAvance('SEP', cronogramasE, '2025'),
                keySep2025: getIdByMonthAndYear('SEP', '2025', cronogramasE, subtarea.id),
                oct2025: getAvance('OCT', cronogramasE, '2025'),
                keyOct2025: getIdByMonthAndYear('OCT', '2025', cronogramasE, subtarea.id),
                nov2025: getAvance('NOV', cronogramasE, '2025'),
                keyNov2025: getIdByMonthAndYear('NOV', '2025', cronogramasE, subtarea.id),
                dic2025: getAvance('DIC', cronogramasE, '2025'),
                keyDic2025: getIdByMonthAndYear('DIC', '2025', cronogramasE, subtarea.id),
                metaAcu: sumaE2024_2025,
                avanceAcu: avanceAcu,
                fechaFinReal: rowData.fechaFinReal,
                medioVerificacion: '',
                observacion: '',
                ruta: '',
                actividad: '',
              },
            ];
            dataTable.forEach(item => {
              if (item.pe === 'P') {
                item.avanceEne2024 = calcularAvance(item.ene2024, dataTable[1].ene2024);
                item.avanceFeb2024 = calcularAvance(item.feb2024, dataTable[1].feb2024);
                item.avanceMar2024 = calcularAvance(item.mar2024, dataTable[1].mar2024);
                item.avanceAbr2024 = calcularAvance(item.abr2024, dataTable[1].abr2024);
                item.avanceMay2024 = calcularAvance(item.may2024, dataTable[1].may2024);
                item.avanceJun2024 = calcularAvance(item.jun2024, dataTable[1].jun2024);
                item.avanceJul2024 = calcularAvance(item.jul2024, dataTable[1].jul2024);
                item.avanceAgo2024 = calcularAvance(item.ago2024, dataTable[1].ago2024);
                item.avanceSep2024 = calcularAvance(item.sep2024, dataTable[1].sep2024);
                item.avanceOct2024 = calcularAvance(item.oct2024, dataTable[1].oct2024);
                item.avanceNov2024 = calcularAvance(item.nov2024, dataTable[1].nov2024);
                item.avanceDic2024 = calcularAvance(item.dic2024, dataTable[1].dic2024);
                item.avanceEne2025 = calcularAvance(item.ene2025, dataTable[1].ene2025);
                item.avanceFeb2025 = calcularAvance(item.feb2025, dataTable[1].feb2025);
                item.avanceMar2025 = calcularAvance(item.mar2025, dataTable[1].mar2025);
                item.avanceAbr2025 = calcularAvance(item.abr2025, dataTable[1].abr2025);
                item.avanceMay2025 = calcularAvance(item.may2025, dataTable[1].may2025);
                item.avanceJun2025 = calcularAvance(item.jun2025, dataTable[1].jun2025);
                item.avanceJul2025 = calcularAvance(item.jul2025, dataTable[1].jul2025);
                item.avanceAgo2025 = calcularAvance(item.ago2025, dataTable[1].ago2025);
                item.avanceSep2025 = calcularAvance(item.sep2025, dataTable[1].sep2025);
                item.avanceOct2025 = calcularAvance(item.oct2025, dataTable[1].oct2025);
                item.avanceNov2025 = calcularAvance(item.nov2025, dataTable[1].nov2025);
                item.avanceDic2025 = calcularAvance(item.dic2025, dataTable[1].dic2025);
              }
              if (item.pe === 'E') {
                item.avanceEne2024 = calcularAvance(item.ene2024, dataTable[0].ene2024);
                item.avanceFeb2024 = calcularAvance(item.feb2024, dataTable[0].feb2024);
                item.avanceMar2024 = calcularAvance(item.mar2024, dataTable[0].mar2024);
                item.avanceAbr2024 = calcularAvance(item.abr2024, dataTable[0].abr2024);
                item.avanceMay2024 = calcularAvance(item.may2024, dataTable[0].may2024);
                item.avanceJun2024 = calcularAvance(item.jun2024, dataTable[0].jun2024);
                item.avanceJul2024 = calcularAvance(item.jul2024, dataTable[0].jul2024);
                item.avanceAgo2024 = calcularAvance(item.ago2024, dataTable[0].ago2024);
                item.avanceSep2024 = calcularAvance(item.sep2024, dataTable[0].sep2024);
                item.avanceOct2024 = calcularAvance(item.oct2024, dataTable[0].oct2024);
                item.avanceNov2024 = calcularAvance(item.nov2024, dataTable[0].nov2024);
                item.avanceDic2024 = calcularAvance(item.dic2024, dataTable[0].dic2024);
                item.avanceEne2025 = calcularAvance(item.ene2025, dataTable[0].ene2025);
                item.avanceFeb2025 = calcularAvance(item.feb2025, dataTable[0].feb2025);
                item.avanceMar2025 = calcularAvance(item.mar2025, dataTable[0].mar2025);
                item.avanceAbr2025 = calcularAvance(item.abr2025, dataTable[0].abr2025);
                item.avanceMay2025 = calcularAvance(item.may2025, dataTable[0].may2025);
                item.avanceJun2025 = calcularAvance(item.jun2025, dataTable[0].jun2025);
                item.avanceJul2025 = calcularAvance(item.jul2025, dataTable[0].jul2025);
                item.avanceAgo2025 = calcularAvance(item.ago2025, dataTable[0].ago2025);
                item.avanceSep2025 = calcularAvance(item.sep2025, dataTable[0].sep2025);
                item.avanceOct2025 = calcularAvance(item.oct2025, dataTable[0].oct2025);
                item.avanceNov2025 = calcularAvance(item.nov2025, dataTable[0].nov2025);
                item.avanceDic2025 = calcularAvance(item.dic2025, dataTable[0].dic2025);
              }
            });
            return dataTable;
          }) : []
        );
      }
      return [];
    }).flat();
  };

  const procesarMpoa = async (valor) => {
    if (valor && valor.ruta) {
      const Mpoa = valor.ruta.split("/poa/")[1];
      if (Mpoa === '2.2.1') {
        return null;
      }
      // Función para determinar qué actividad es especial y cómo manejarla
      const manejarActividadEspecial = (actividad) => {
        switch (actividad) {
          case '2.2.1.1':
            return '2.2.1'; // Se usa como base para encontrar nodos relacionados
          case '2.2.1.2':
            return '2.2.1'; // Se usa como base para encontrar nodos relacionados
          case '2.2.1.3':
            return '2.2.1'; // Se usa como base para encontrar nodos relacionados
          default:
            return actividad; // Para actividades normales, retorna el valor original
        }
      };
      const actividadBase = manejarActividadEspecial(Mpoa);
      try {
        const dataCompleta = await fetchPoaData();
        const dataPoa = dataCompleta.dataPoa;
        const findNodePath = (node, path = []) => {
          path.push(node);
          if (node.codigo_tarea === actividadBase && !['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
            return path;
          }
          if (['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
            if (node.children && node.children.length > 0) {
              for (let child of node.children) {
                if (Mpoa === '2.2.1.2' && child.key === 'indicador_33') {
                  path.push(child);
                  return path;
                }
                if (Mpoa === '2.2.1.3' && child.key === 'indicador_34') {
                  path.push(child);
                  return path;
                }
              }
            }
          }
          if (node.children && node.children.length > 0) {
            for (let child of node.children) {
              const result = findNodePath(child, [...path]);
              if (result) return result;
            }
          }
          return null;
        };
        let matchedPath = null;

        for (let nodo of dataPoa) {
          matchedPath = findNodePath(nodo);
          if (matchedPath) break;
        }
        if (matchedPath) {
          const initialData = extractData(matchedPath);
          const actividadData = initialData[0].actividadData;
          const tareaEncontrada = actividadData.find((item) => {
            if (['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
              return (
                item.codigo_tarea === actividadBase &&
                ((Mpoa === '2.2.1.2' && item.key === 'indicador_33') ||
                  (Mpoa === '2.2.1.3' && item.key === 'indicador_34'))
              );
            }
            return item.codigo_tarea === actividadBase;
          });
          if (tareaEncontrada) {
            tareaEncontrada.proyecto = valor.proyecto;
            setOpenModal1(true);
            setItemParams(tareaEncontrada);
          } else {
            setOpenModal1(false);
            setItemParams(null);
          }
        } else {
          console.warn("No se encontró ningún nodo coincidente");
        }
      } catch (error) {
        console.error("Error al obtener los datos:", error);
      }
    } else {
      setOpenModal1(false);
    }
  };

  const extractData = (children) => {
    return children.flatMap(child => {
      if (child.children && child.tipo === 'Actividad') {
        const actividadData = child.children.flatMap(grandchild => {
          if (grandchild.children && grandchild.tipo === 'Tarea') {
            return grandchild.children.flatMap(greatGrandchild => {
              if (greatGrandchild.tipo === 'Indicador') {
                const fecha = greatGrandchild.meta_e?.[0]?.fecha;
                const fechaFormateada = fecha ? new Date(fecha).toLocaleDateString('es-ES') : '-';
                const metaProyectada = greatGrandchild.meta_proyectada || 0;
                const valorEjecutado = Math.floor((greatGrandchild.meta_e?.reduce((total, meta) => total + meta.valor, 0) || 0) * 100) / 100;
                const porcentajeEjecucion = metaProyectada > 0 ? (valorEjecutado / metaProyectada) * 100 : 0;
                const medioV = greatGrandchild.medioVerificacion || null;
                return {
                  key: greatGrandchild.key,
                  codigo_tarea: grandchild.codigo_tarea,
                  codActividad: grandchild.codActividad,
                  nameActividad: child.actividad,
                  tarea: grandchild.tarea,
                  indicador: greatGrandchild.title,
                  medioV: medioV,
                  meta_proyectada: metaProyectada,
                  valorEjecutado: valorEjecutado,
                  porcentaje_ejecucion: porcentajeEjecucion % 1 === 0 ? porcentajeEjecucion.toFixed(0) : porcentajeEjecucion.toFixed(1),
                  porcentaje_avance: greatGrandchild.meta_e?.[0]?.valor || 'N/A',
                  fechas: fechaFormateada,
                  responsables: 'N/A',
                  soportes: 'N/A',
                  datoIndicador: greatGrandchild,
                };
              }
              return [];
            });
          }
          return [];
        });

        const totalMetaProyectada = actividadData.reduce((total, data) => total + (data.meta_proyectada || 0), 0);
        const totalValorEjecutado = actividadData.reduce((total, data) => total + (data.meta_proyectada * (parseFloat(data.porcentaje_ejecucion) / 100) || 0), 0);
        const porcentajeEjecucionActividad = totalMetaProyectada > 0 ? (totalValorEjecutado / totalMetaProyectada) * 100 : 0;

        return [{
          actividadTitle: child.title,
          actividad: child.actividad,
          porcentajeEjecucionActividad: porcentajeEjecucionActividad.toFixed(1),
          actividadData: actividadData
        }];
      }
      return [];
    });
  };

  const hideModalMedios = () => {
    setOpenModal1(false);
  };

  if (loading) return <div>Loading...</div>;

  if (error) {
    return <div>El proyecto no existe o no se encontró información.</div>;
  }

  const items = [
    {
      key: '1',
      span: 3,
      label: (
        <div style={{ textAlign: 'center' }}>
          {headerPO?.titulo || 'Nombre del Proyecto no disponible'}
        </div>
      ),
    },
    {
      key: '2',
      span: 3,
      label: (
        <div style={{ textAlign: 'center' }}>
          {headerPO?.convenio || 'Convenio no disponible'}
        </div>
      ),
    }, {
      key: '3',
      span: 3,
      label: (
        <div style={{ textAlign: 'center' }}>
          {headerPO?.factibilidad || 'Factibilidad no disponible'}
        </div>
      ),
    }, {
      key: '4',
      span: 3,
      label: (
        <div style={{ textAlign: 'center' }}>
          {headerPO?.territorio_zona || 'Territorio / Zona PDET no disponible'}
        </div>
      ),
    },
    {
      key: '5',
      span: 1,
      label: (
        <div style={{ textAlign: 'center' }}>
          <strong>Tiempo de Duración:</strong>{' '}
          {headerPO?.duracion ?? 'Duración no disponible'}
        </div>
      ),
    },
    {
      key: '6',
      span: 1,
      label: (
        <div style={{ textAlign: 'center' }}>
          <strong>Fecha Inicio:</strong>{' '}
          {headerPO?.fecha_inicio
            ? new Intl.DateTimeFormat('es-ES', { dateStyle: 'long' }).format(new Date(headerPO.fecha_inicio))
            : 'Fecha inicio no disponible'}
        </div>
      ),
    },
    {
      key: '7',
      span: 1,
      label: (
        <div style={{ textAlign: 'center' }}>
          <strong>Fecha Terminación:</strong>{' '}
          {headerPO?.fecha_terminacion
            ? new Intl.DateTimeFormat('es-ES', { dateStyle: 'long' }).format(new Date(headerPO.fecha_terminacion))
            : 'Fecha terminación no disponible'}
        </div>
      ),
    },
  ];

  const formatFechaToISO = (fecha) => {
    if (/^\d{4}-\d{2}-\d{2}$/.test(fecha)) {
      const [year, month, day] = fecha.split('-');
      const formattedDate = new Date(year, month - 1, day);
      if (isNaN(formattedDate)) {
        throw new Error(`Fecha no válida: ${fecha}`);
      }
      return formattedDate.toISOString();
    }

    // Detecta si la fecha está en formato DD/MM/YYYY
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(fecha)) {
      const [day, month, year] = fecha.split('/');
      const formattedDate = new Date(year, month - 1, day);
      if (isNaN(formattedDate)) {
        throw new Error(`Fecha no válida: ${fecha}`);
      }
      return formattedDate.toISOString();
    }

    // Si no coincide con ninguno de los formatos, lanza un error
    throw new Error(`Formato de fecha inválido: ${fecha}`);
  };


  const handleSaveDate = async (value, record) => {
    const codIndicador = record.codIndicador;
    const newfechaFinReal = value.fechaFinReal;
    const tipo = 'medio_verificacion';
    const newData = [...actividades];
    newData.forEach((actividad) => {
      actividad.children.forEach((tarea) => {
        tarea.children.forEach((cronograma) => {
          if (cronograma.id === codIndicador) {
            cronograma.fecha_f_real = formatFechaToISO(newfechaFinReal);
          }
        });
      });
    });
    setActividades(newData);
    setTareasSubtareas(mapTareasSubtareas(newData));
    try {
      const response = await updateFecha(codIndicador, newfechaFinReal, tipo);

      if (response.status === 200) {
        message.success('Avance actualizado correctamente');
      } else {
        message.error('No se pudo actualizar el avance');
      }
    } catch (error) {
      console.error('Error en handleSaveDate:', error);
      message.error('Error al actualizar el avance');
    }
  };


  const handleSave = async (value, key, dataIndex) => {
    const valorActualizado = value[dataIndex];
    if (valorActualizado === undefined || valorActualizado === null) {
      return; // No hacer nada si es undefined o null
    }
    const newData = [...actividades];
    newData.forEach(actividad => {
      actividad.children.forEach(tarea => {
        tarea.children.forEach(cronograma => {
          const cronogramaP = cronograma.cronogramasP.find(item => item.id === Number(key));
          const cronogramaE = cronograma.cronogramasE.find(item => item.id === Number(key));
          if (cronogramaP) {
            cronogramaP.avance = valorActualizado;
          }
          if (cronogramaE) {
            cronogramaE.avance = valorActualizado;
          }
        });
      });
    });
    setActividades(newData);
    setTareasSubtareas(mapTareasSubtareas(newData));
    try {
      const response = await updateAvance(key, valorActualizado);
      if (response.status === 200) {
        message.success('Avance actualizado correctamente');
      }
    } catch (error) {
      message.error('Error al actualizar el avance: '); // Muestra un mensaje de error
    }
  };

  const handleNoInfoClick = (datos) => { 
    console.log("datos: ",datos);
    setActividadSelect(datos);
    showModal();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const hideModal = () => {
    setIsModalVisible(false);
  };

  function capitalizarPrimeraLetra(texto) {
    return texto.toLowerCase().charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
  }

  const columnss = [
    { title: 'Tarea', dataIndex: 'tarea', key: 'tarea', width: 300, ellipsis: false, onCell: (record, rowIndex) => ({ rowSpan: rowIndex % 2 === 0 ? 2 : 0, }), render: (text, record, rowIndex) => rowIndex % 2 === 0 ? (<div style={{ width: '482px', whiteSpace: 'pre-line', wordWrap: 'break-word', textAlign: 'justify' }}>{text}</div>) : null },
    { title: 'Indicador', dataIndex: 'indicador', key: 'indicador', width: 200, onCell: (record, rowIndex) => ({ rowSpan: rowIndex % 2 === 0 ? 2 : 0, }) },
    { title: 'Meta', dataIndex: 'meta', key: 'meta', width: 100, align: 'center', onCell: (record, rowIndex) => ({ rowSpan: rowIndex % 2 === 0 ? 2 : 0, }) },
    { title: 'Fecha Inicio', dataIndex: 'fechaInicio', key: 'fechaInicio', width: 150, onCell: (record, rowIndex) => ({ rowSpan: rowIndex % 2 === 0 ? 2 : 0, }) },
    { title: 'Fecha Fin', dataIndex: 'fechaFin', key: 'fechaFin', width: 150, onCell: (record, rowIndex) => ({ rowSpan: rowIndex % 2 === 0 ? 2 : 0, }) },
    { title: 'P/E', dataIndex: 'pe', key: 'pe', width: 50, align: 'center' },
    {
      title: 'Cronograma 2024',
      classNames: 'color2024',
      children: columnasDinamicas2024,
    },
    {
      title: 'Cronograma 2025',
      children: columnasDinamicas2025,
    },
    {
      title: 'Seguimiento Acumulado', children: [
        { title: 'Meta y Avance Acumulado', dataIndex: 'metaAcu', key: 'metaAcu', width: 150, render: (value) => value || '-', align: 'center' },
        { title: '% de Avance Acumulado', dataIndex: 'avanceAcu', key: 'avanceAcu', width: 100, render: (text) => { const progreso = parseFloat(text); const { color, backgroundColor } = getAvanceStyle(progreso); return (<div style={{ color, backgroundColor }}>{text}</div>); }, align: 'center', onCell: (record, rowIndex) => ({ rowSpan: rowIndex % 2 === 0 ? 2 : 0, }) }
      ]
    },
    {
      title: 'FECHA FIN REAL',
      dataIndex: 'fechaFinReal',
      key: 'fechaFinReal',
      width: 150,
      align: 'center',
      editable: true,
      render: (text, record) => {
        if (!text || text === 'Sin fecha') {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                margin: '2px',
              }}
            >
              <span>Sin fecha</span>
              <Tooltip title="Por favor diligenciar este campo, con la fecha en la que se realizó la tarea, en formato dd/mm/año">
                <WarningOutlined
                  style={{ fontSize: '16px', color: 'red', cursor: 'pointer' }}
                />
              </Tooltip>
            </div>
          );
        }
        return text;
      },
      onCell: (record, rowIndex) => ({
        record,
        editable: true,
        dataType: 'date',
        handleSave: (value) =>
          handleSaveDate(value, record),
        rowSpan: rowIndex % 2 === 0 ? 2 : 0,
      }),

    },
    {
      title: 'MEDIO DE VERIFICACIÓN',
      dataIndex: 'medioVerificacion',
      key: 'medioVerificacion',
      width: 250,
      ellipsis: false,
      onCell: (record, rowIndex) => ({ rowSpan: rowIndex % 2 === 0 ? 2 : 0, }),
      render: (text) => (
        <div style={{ width: '400px', whiteSpace: 'pre-line', wordWrap: 'break-word', textAlign: 'justify' }}>
          {text}
        </div>
      ),
    },
    {
      title: 'Ruta Soportes',
      dataIndex: 'ruta',
      align: 'center',
      key: 'ruta',
      width: 150,
      onCell: (record, rowIndex) => ({ rowSpan: rowIndex % 2 === 0 ? 2 : 0, }),
      render: (text, record) =>
        /^(https?:\/\/)/i.test(text) ? (
          <Tooltip title={text || 'No hay información disponible'}>
            <LinkOutlined
              style={{ fontSize: '16px', color: '#317EDA', cursor: 'pointer' }}
              onClick={() => procesarMpoa(record)} // Pasar el registro actual
            />
          </Tooltip>
        )
          : (
            <Tooltip title={text || 'No hay información disponible'}>
              <InfoCircleOutlined
                style={{ fontSize: '16px', color: '#8ABF45', cursor: 'pointer' }}
                onClick={() => handleNoInfoClick(record)} // Pasar el registro actual
              />
            </Tooltip>
          ),
    }
  ];

  function getIdByMonthAndYear(month, year, cronogramas, id_indicador) {
    const cronograma = cronogramas.find(c => c.mes === month && c.ano === year && c.indicador_id === id_indicador);
    return cronograma ? cronograma.id : null; // Retorna el id si lo encuentra, de lo contrario null
  }

  const getAvanceStyle = (progreso) => {
    const color = progreso < 30 ? 'red' : progreso < 60 ? 'orange' : 'green';
    const backgroundColor = progreso < 30 ? 'rgba(255, 0, 0, 0.1)' : progreso < 60 ? 'rgba(255, 165, 0, 0.1)' : 'rgba(0, 128, 0, 0.1)';
    return { color, backgroundColor };
  };

  const renderPanels = () => {
    if (!Array.isArray(actividades)) {
      return <div>No hay datos disponibles.</div>;
    }

    return actividades.map((actividad, index) => (
      <Panel
        style={{
          backgroundColor: '#042956',
          color: 'white',
        }}
        className="site-layout-background"
        header={
          <span style={{ color: 'white' }}>
            Actividad: {actividad.codigo_actividad} - {actividad.descripcion}
          </span>
        }
        key={actividad.codigo_actividad}
      >
        <div className="tabla-container">
          <div className="custom-ant-table-wrapper">
            <Table
              bordered={true}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              width="100px"
              className="table-curso-fortalecimiento"
              columns={columnss}
              dataSource={tareasSubtareas}
              //rowKey={index}
              pagination={false}
              loading={loading}
              scroll={{
                x: 'max-content',
                y: 600,
              }}
            />
          </div>
        </div>
      </Panel>
    ));
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible); // Cambiar el estado entre visible y oculto
  };

  const regresar = () => {
    regresarAnterior();
    cerrarModal();
  };

  const handleEditDateClick = () => {
    setEditingDate(true);
  };

  const handleDateChange = async (date, dateString, headerPO) => {
    if (date && headerPO) {
      const fecha = dayjs(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD');
      const oid = headerPO?.children?.[0]?.id;
      const tipo = "detalle";
      const updatedHeaderPO = { ...headerPO };
      updatedHeaderPO.fecha_actualizacion = formatFechaToISO(fecha);
      if (updatedHeaderPO.children && updatedHeaderPO.children.length > 0) {
        updatedHeaderPO.children[0].fecha_actualizacion = formatFechaToISO(fecha);
      }
      try {
        await updateFecha(oid, fecha, tipo);
        setHeaderPO(updatedHeaderPO);
        setEditingDate(false);
        message.success('Fecha actualizada correctamente');
      } catch (error) {
        message.error('Hubo un error al actualizar la fecha');
        console.error('Error al actualizar la fecha:', error);
      }
    }
  };

  return (
    <div className="">
      <Layout>
        <div className="d-flex justify-content-center mb-3">
          <div className="d-flex justify-content-center mb-3" style={{ gap: "10px" }}>
            <Tooltip title="Regresar">
              <Button
                type="primary"
                shape="circle"
                icon={<ArrowLeftOutlined />}
                onClick={regresar}
              />
            </Tooltip>
            <Tooltip title={isVisible ? "Cerrar contenido" : "Abrir contenido"}>
              <Button
                type="primary"
                shape="circle"
                icon={isVisible ? <MoreOutlined /> : <MenuOutlined />}
                onClick={toggleVisibility}
              />
            </Tooltip>
          </div>
        </div>
        <Collapse activeKey={isVisible ? ["1"] : []} ghost>
          <Panel key="1" showArrow={false} header={null}>
            <div className="row mb-2">
              <Descriptions title="" layout="vertical" bordered items={items} />
            </div>
          </Panel>
        </Collapse>
        <div style={{ overflowX: "auto" }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <Title level={4} className="gradient-text" style={{ margin: 0 }}>
              {selectedProject.codigo} - {selectedProject.proyecto}
            </Title>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                fontSize: '16px',
                marginTop: '5px',
                border: '1px solid #A19E9E',
                fontWeight: 'bold',
                padding: '5px',
                borderRadius: '4px',
              }}
              onClick={handleEditDateClick}
            >
              {editingDate ? (
                <DatePicker
                  onChange={(date, dateString) => handleDateChange(date, dateString, headerPO)} // Usamos el nuevo método
                  value={
                    headerPO?.fecha_actualizacion
                      ? dayjs(headerPO.fecha_actualizacion) // Convertimos a dayjs
                      : null
                  }
                  format="DD/MM/YYYY"
                />
              ) : headerPO?.fecha_actualizacion ? (
                <span>{moment(headerPO.fecha_actualizacion).format('DD/MM/YYYY')}</span>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                  <span style={{ color: '#575757' }}>Sin fecha de actualización registrada</span>
                  <Tooltip title="*Por favor diligenciar este campo, con la fecha de corte en la que se actualizaron las metas ejecutadas del POA.">
                    <WarningOutlined style={{ fontSize: '16px', color: 'red', cursor: 'pointer' }} />
                  </Tooltip>
                </div>
              )}
            </div>

          </div>
          <Title level={5} className="justified-title" style={{ marginTop: '10px' }}>
            {selectedFase.nombre_corto}
          </Title>
          <Collapse accordion>{renderPanels()}</Collapse>
        </div>
        <div className="d-flex justify-content-center">
          <Modal
            title={<span className="custom-modal-title">Medios de verificación</span>}
            open={isModalVisible}
            onCancel={hideModal}
            footer={null}
            width="95%"
            style={{ top: 20 }}
          >
            <FileManagerPOA2 codigo_tarea={actividadSelect} />
          </Modal>
        </div>
        <div className="d-flex justify-content-center">
          <Modal
            title={<span className="custom-modal-title">Medios de verificación</span>}
            open={openModal1}
            onCancel={hideModalMedios}
            footer={null}
            width="95%"
            style={{ top: 20 }}
          >
            <FileManagerPOAN codigo_tarea={itemParams} />
          </Modal>
        </div></Layout>
    </div>
  );
};

export default PoaPlanOperativoTB;
