import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint = '/api/descargar-archivosPOA/';

export const viewFile = async (ruta) => {
    try {
        const response = await axios.post(
            `${baseUrl}${endpoint}`,
            { ruta },
            { responseType: 'blob' } // Asegura que el archivo se reciba como un blob
        );

        // Crear una URL para el blob y abrirla en una nueva pestaña
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        window.open(url, '_blank'); // Abre la URL en una nueva pestaña
    } catch (error) {
        console.error('Error al abrir el archivo:', error);
        throw error;
    }
};

