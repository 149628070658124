import React, { useState } from 'react';
import { Input, DatePicker, message } from 'antd';
import moment from 'moment';

const EditableCell = ({ title, editable, children, value, record, handleSave, dataType, ...restProps }) => {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (e) => {
    const input = e.target.value;

    // Validar si el valor es numérico
    if (/^\d*$/.test(input)) {
      setInputValue(input);
    } else {
      // Mostrar alerta si el valor no es numérico
      message.warning('Solo se permiten números.');
    }
  };

  const save = () => {
    // Si el valor no es numérico o está vacío, restablecer a 0
    const finalValue = /^\d+$/.test(inputValue) ? inputValue : '0';
    if (finalValue !== inputValue) {
      message.info('El valor no era válido y se ha restablecido a 0.');
    }

    handleSave({ ...record, [title]: finalValue });
    setEditing(false);
  };

  const handleDateChange = (date) => {
    const formattedDate = date ? date.format('YYYY-MM-DD') : '';
    handleSave({ ...record, fechaFinReal: formattedDate });
    setEditing(false);
  };

  return (
    <td {...restProps}>
      {editable ? (
        editing ? (
          dataType === 'date' ? (
            <DatePicker
              value={
                inputValue && moment(inputValue, 'YYYY-MM-DD', true).isValid()
                  ? moment(inputValue, 'YYYY-MM-DD')
                  : null
              }
              onChange={handleDateChange}
              open={true}
              onOpenChange={(open) => {
                if (!open) setEditing(false);
              }}
              autoFocus
            />
          ) : (
            <Input
              value={inputValue}
              onChange={handleChange}
              onBlur={save}
              autoFocus
              inputMode="numeric" // Sugerir teclado numérico en dispositivos móviles
              pattern="\d*" // Forzar entrada numérica en navegadores compatibles
            />
          )
        ) : (
          <div
            className="editable-cell-value-wrap"
            onClick={() => setEditing(true)}
          >
            {children || 'Sin fecha'}
          </div>
        )
      ) : (
        children || 'Sin fecha'
      )}
    </td>
  );
};

export default EditableCell;
