import React, { useEffect, useState } from 'react';
import { Card, Typography, Row, Col, Layout, Collapse, Spin, Alert, Tooltip, Button, Modal } from 'antd';
import { useParams } from 'react-router-dom';
import { ArrowLeftOutlined, FolderOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProyectoService from '../../services/estructuracion/get_proyecto_id'; // Ajusta la ruta según sea necesario
import Header160 from '../header/header';
import './proyecto_estructuracion.css';
import { faFilePdf, faFileWord, faFileExcel, faFileAlt, faFile } from '@fortawesome/free-solid-svg-icons';
import { viewOrDownloadFile } from '../../services/estructuracion/view_supp'; // Importa el servicio
import { useNavigate } from 'react-router-dom';
import { fetchPoaData } from '../../services/poa/get_poa';
import FileManagerPOAN from '../poaReporte/FileManagerPOAN';


const { Title } = Typography;
const { Panel } = Collapse;
const iconMap = {
    pdf: faFilePdf,
    doc: faFileWord,
    docx: faFileWord,
    xls: faFileExcel,
    xlsx: faFileExcel,
    txt: faFileAlt,
    default: faFile // Icono por defecto para otros tipos de archivos
};

const ProyectoView = () => {

    const { oid } = useParams();
    const [proyecto, setProyecto] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [itemParams, setItemParams] = useState(false);
    const [openModalVisitasEcas, setOpenModalVisitasEcas] = useState(false);


    useEffect(() => {
        const fetchProyectoData = async () => {
            try {
                const data = await ProyectoService.fetchProyectoByOid(oid);
                setProyecto(data);
            } catch (err) {
                console.error("Error al obtener los datos del proyecto:", err);
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        if (oid) {
            fetchProyectoData();
        } else {
            //console.warn("No se proporcionó un OID.");
        }
    }, [oid]);

    const procesarMpoa = async (Mpoa, paso, proyecto) => {
        if (Mpoa) {
            if (Mpoa === '2.2.1') {
                return null;
            }
            const manejarActividadEspecial = (actividad) => {
                switch (actividad) {
                    case '2.2.1.1':
                        return '2.2.1';
                    case '2.2.1.2':
                        return '2.2.1';
                    case '2.2.1.3':
                        return '2.2.1';
                    default:
                        return actividad;
                }
            };
            const actividadBase = manejarActividadEspecial(Mpoa);
            try {
                const dataCompleta = await fetchPoaData();
                const dataPoa = dataCompleta.dataPoa;
                const findNodePath = (node, path = []) => {
                    path.push(node);
                    if (node.codigo_tarea === actividadBase && !['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
                        return path;
                    }
                    if (['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
                        if (node.children && node.children.length > 0) {
                            for (let child of node.children) {
                                if (Mpoa === '2.2.1.2' && child.key === 'indicador_33') {
                                    path.push(child);
                                    return path;
                                }
                                if (Mpoa === '2.2.1.3' && child.key === 'indicador_34') {
                                    path.push(child);
                                    return path;
                                }
                            }
                        }
                    }

                    if (node.children && node.children.length > 0) {
                        for (let child of node.children) {
                            const result = findNodePath(child, [...path]);
                            if (result) return result;
                        }
                    }
                    return null;
                };
                let matchedPath = null;

                for (let nodo of dataPoa) {
                    matchedPath = findNodePath(nodo);
                    if (matchedPath) break;
                }
                if (matchedPath) {
                    const initialData = extractData(matchedPath);
                    const actividadData = initialData[0].actividadData;
                    const tareaEncontrada = actividadData.find((item) => {
                        if (['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
                            return (
                                item.codigo_tarea === actividadBase &&
                                ((Mpoa === '2.2.1.2' && item.key === 'indicador_33') ||
                                    (Mpoa === '2.2.1.3' && item.key === 'indicador_34'))
                            );
                        }
                        return item.codigo_tarea === actividadBase;
                    });
                    if (tareaEncontrada) {
                        tareaEncontrada.proyecto = proyecto;
                        tareaEncontrada.paso = paso;
                        setOpenModalVisitasEcas(true);
                        setItemParams(tareaEncontrada);
                    } else {
                        setOpenModalVisitasEcas(false);
                        setItemParams(null);
                    }
                } else {
                    console.warn("No se encontró ningún nodo coincidente");
                }
            } catch (error) {
                console.error("Error al obtener los datos:", error);
            }
        } else {
            setOpenModalVisitasEcas(false);
        }
    };

    const extractData = (children) => {
        return children.flatMap(child => {
            if (child.children && child.tipo === 'Actividad') {
                const actividadData = child.children.flatMap(grandchild => {
                    if (grandchild.children && grandchild.tipo === 'Tarea') {
                        return grandchild.children.flatMap(greatGrandchild => {
                            if (greatGrandchild.tipo === 'Indicador') {
                                const fecha = greatGrandchild.meta_e?.[0]?.fecha;
                                const fechaFormateada = fecha ? new Date(fecha).toLocaleDateString('es-ES') : '-';
                                const medioV = greatGrandchild.medioVerificacion || null;
                                return {
                                    key: greatGrandchild.key,
                                    codigo_tarea: grandchild.codigo_tarea,
                                    codActividad: grandchild.codActividad,
                                    nameActividad: child.actividad,
                                    tarea: grandchild.tarea,
                                    indicador: greatGrandchild.title,
                                    medioV: medioV,
                                    fechas: fechaFormateada,
                                    responsables: 'N/A',
                                    soportes: 'N/A',
                                    datoIndicador: greatGrandchild,
                                };
                            }
                            return [];
                        });
                    }
                    return [];
                });
                return [{
                    actividadTitle: child.title,
                    actividad: child.actividad,
                    actividadData: actividadData
                }];
            }
            return [];
        });
    };

    if (loading) {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh' // Ocupa toda la altura de la ventana
            }}>
                <Spin size="large" />
            </div>
        );
    }


    if (error) {
        return <div style={{ padding: '20px' }}><Alert message="Error al cargar el proyecto" type="error" /></div>;
    }

    if (!proyecto) {
        return <div style={{ padding: '20px' }}><Alert message="No se encontró el proyecto" type="warning" /></div>;
    }

    const truncateString = (str, maxLength) => {
        if (str.length > maxLength) {
            return str.slice(0, maxLength) + '...';
        }
        return str;
    };

    const getFileIcon = (extension) => {
        return iconMap[extension] || iconMap.default;
    };

    const handleFileClick = async (soporte) => {
        const extension = soporte.extension.toLowerCase().replace('.', '');
        // Define los tipos de archivos que se pueden visualizar directamente
        const viewableExtensions = ['pdf', 'jpg', 'jpeg', 'png', 'gif', 'txt', 'doc', 'docx', 'xls', 'xlsx'];
        try {
            if (viewableExtensions.includes(extension)) {
                // Si es un archivo que se puede ver, llamamos al servicio para visualizarlo
                await viewOrDownloadFile(soporte.ruta, soporte.nombre);
            } else {
                // Si es un archivo que no se puede visualizar, llamamos al servicio para descargarlo
                await viewOrDownloadFile(soporte.ruta, soporte.nombre);
            }
        } catch (error) {
            console.error('Error al ver o descargar el archivo:', error);
        }
    };

    const handleCardClick = () => {
        navigate(`/es_proyectos`);
    };

    const handleFolderClick = (tarea, paso, proyecto) => {
        procesarMpoa(tarea.codigo, paso.nombre_ruta, proyecto)
    };

    const hideModalVisitasEcas = () => {
        setOpenModalVisitasEcas(false);
        setItemParams(null);
    };

    return (
        <Layout className="container">
            <div className="header-filter-container d-flex align-items-center justify-content-between">
                <Header160 />
                <div className="container elemtos">
                    <div className="input-group-container">
                        <div>
                            <Button
                                onClick={() => handleCardClick()}
                                style={{ width: 125 }}
                                icon={<ArrowLeftOutlined />}
                            >
                                Regresar
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ padding: '20px', overflowY: 'auto' }}>
                <Title level={3} className="gradient-text">
                    {proyecto.codigo} - {proyecto.nombre_proyecto}
                </Title>
                <Title level={5} className="justified-title">
                    {proyecto.actividades && proyecto.actividades[0]?.codigo} {proyecto.actividades && proyecto.actividades[0]?.nombre_actividad || 'Nombre de la Actividad'}
                </Title>
                <Collapse accordion >
                    {proyecto.actividades.map((actividad, index) => (
                        <Collapse accordion key={index} className="collate-background-estructuracion">
                            {actividad.tareas.map((tarea, tareaIndex) => (
                                <Panel
                                    header={`${tarea.codigo} - ${tarea.nombre_tarea}`}
                                    key={`tarea-${tareaIndex}`}
                                    className="custom-panel-estructuracion"
                                >
                                    <Row gutter={24} justify="center" className="custom-background-estructuracion">
                                        {tarea.indicadores.map((indicador, indicadorIndex) => (
                                            <React.Fragment key={`indicador-${indicadorIndex}`}>
                                                <Row gutter={16} justify="center" style={{ width: '100%' }}>
                                                    <Col span={24} justify="center">
                                                        <div style={{ textAlign: 'center', margin: '0px' }}>
                                                            <h4 style={{ textAlign: 'center' }}>Indicador</h4>
                                                            <h3 style={{ textAlign: 'center' }}>{indicador.nombre_indicador}</h3>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row gutter={16} justify="space-around" align="top">
                                                    {indicador.rutas.map((ruta, rutaIndex) => (
                                                        <Col key={`ruta-${rutaIndex}`} className="custom-col">
                                                            <div className="custom-card-container">
                                                                <Card
                                                                    className="custom-card custom-card-header"
                                                                    title={
                                                                        <div className="card-title">
                                                                            <div>
                                                                            </div>
                                                                            <Tooltip title="Ver archivos">
                                                                                <span
                                                                                    onClick={() => handleFolderClick(tarea, ruta, proyecto.codigo)}
                                                                                    style={{
                                                                                        fontSize: '20px',
                                                                                        cursor: 'pointer',
                                                                                        marginRight: '10px',
                                                                                        color: '#1890ff',
                                                                                        alignItems: 'center',
                                                                                    }}
                                                                                >
                                                                                    <FolderOutlined />
                                                                                </span>
                                                                            </Tooltip>
                                                                            {ruta.nombre_ruta}
                                                                        </div>
                                                                    }
                                                                    bordered={false}
                                                                    style={{ width: '300px', padding: '0px', maxHeight: '400px', overflowY: 'auto' }}
                                                                >
                                                                    {Array.isArray(ruta.soportes) && ruta.soportes.length > 0 ? (
                                                                        <ul>
                                                                            {ruta.soportes.map((soporte, soporteIndex) => {
                                                                                const extension = soporte.extension.toLowerCase().replace('.', '');
                                                                                const fileIcon = getFileIcon(extension);
                                                                                const cleanedName = soporte.nombre.replace(/^\d+-/, '');
                                                                                return (
                                                                                    <li key={`soporte-${soporteIndex}`}>
                                                                                        <button
                                                                                            onClick={() => handleFileClick(proyecto, actividad)}
                                                                                            style={{
                                                                                                display: 'flex',
                                                                                                alignItems: 'center',
                                                                                                background: 'none',
                                                                                                border: 'none',
                                                                                                padding: 0,
                                                                                                cursor: 'pointer',
                                                                                                textAlign: 'left',
                                                                                            }}
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={fileIcon}
                                                                                                style={{ marginRight: '10px' }}
                                                                                            />
                                                                                            <Tooltip title={cleanedName}>
                                                                                                <span>{truncateString(cleanedName, 25)}</span>
                                                                                            </Tooltip>
                                                                                        </button>
                                                                                    </li>
                                                                                );
                                                                            })}
                                                                        </ul>
                                                                    ) : (
                                                                        <div>No se encontraron soportes</div>
                                                                    )}
                                                                </Card>
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </React.Fragment>
                                        ))}
                                    </Row>
                                </Panel>
                            ))}
                        </Collapse>
                    ))}
                </Collapse>
            </div>
            <div className="d-flex justify-content-center">
                    <Modal
                        title={<span className="custom-modal-title">Medios de verificación</span>}
                        open={openModalVisitasEcas}
                        onCancel={hideModalVisitasEcas}
                        footer={null}
                        width="95%"
                        style={{ top: 20 }}
                    >
                        <FileManagerPOAN codigo_tarea={itemParams} />
                    </Modal>
                </div>
        </Layout>
    );
};

export default ProyectoView;
