import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint1 = '/api/proyectosPO';
const endpoint2 = '/api/componentesPO';

const getProyectosPO = async () => {
  try {
    const response = await axios.get(`${baseUrl}${endpoint1}`);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al obtener los proyectos:', error);
    throw error; 
  }
};

const getComponentesPO = async () => {
  try {
    const response = await axios.get(`${baseUrl}${endpoint2}`);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al obtener los componentes:', error);
    throw error; 
  }
};




export { getProyectosPO, getComponentesPO };