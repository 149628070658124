import React, { useEffect, useState } from "react";
import { Row, Col, Card, Button, Tooltip, Input, Layout, Modal, message } from "antd";
import { HomeOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import ImageComponent from '../imageMapping/ImageComponent';
import { getProyectosPO, getComponentesPO } from '../../services/plaOperativo/planOperativoProyectosServices';
import PoaPlanOperativoTB from './PoaPlanOperativoTable';
import { viewFile } from '../../services/planesPedagogicos/planPedagogicoDowService';
import { fetchPoaData } from '../../services/poa/get_poa';
import FileManagerPOAN from './FileManagerPOAN';

import Participantes from '../Participantes/Participantes';
import Ippta from '../Ippta/Ippta';
import Edah from '../EncuestaEdah/Edah';
import AcuerdosComerciales from '../../components/AcuerdosComerciales/AcuerdosComerciales';

const PoaPlanOperativo = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectProject, setSelectedProject] = useState(null);
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [componentes, setComponentes] = useState([]);
    const [error, setError] = useState(null);
    const [isModalVisible2, setIsModalVisible2] = useState(false); 
    const [selectedFase2, setSelectedFase2] = useState(null); 
    const [codigoProyecto, setCodigoProyecto] = useState(null);
    const [isModalVisibleParticipantes, setIsModalVisibleParticipantes] = useState(false);
    const [openModalVisitasEcas, setOpenModalVisitasEcas] = useState(false);
    const [itemParams, setItemParams] = useState(false);
    const [opcionFiltro, setOpcionFiltro] = useState(null);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const { data } = await getProyectosPO();
                console.log("Proyectos:", data);
                setProjects(data);
            } catch (error) {
                console.error("Error fetching projects:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchProjects();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getComponentesPO();
                console.log("Componentes:", response.data);
                setComponentes(response.data);
            } catch (error) {
                console.error("Error fetching components:", error);
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const filteredProjects = projects.filter((project) =>
        project.proyecto.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleOpenModal = (project) => {
        setSelectedProject(project);
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
        //setSelectedComponent(null);
    };

    const regresarModal = () => {
        setIsModalVisible(true);
        //setSelectedComponent(null);
    };

    const handleCardClick = (component) => {
        if (component.oid === 0) {
            downloadFileHandler();
        } else if (component.oid === 1) {
            setSelectedComponent(component);
        } else if (component.oid === 3) {
            setOpcionFiltro(1);
            setCodigoProyecto(selectProject.codigo);
            setIsModalVisibleParticipantes(true);
        } else if (component.oid === 4) {
            procesarMpoa("2.2.1.3") // Visitas
        } else if (component.oid === 5) {
            procesarMpoa("2.2.3") // Ecas
        } else if (component.oid === 6) {
            procesarMpoa("2.1.4") // Actas
        } else if (component.oid === 7) {
            setOpcionFiltro(2);
            setCodigoProyecto(selectProject.codigo);
            setIsModalVisibleParticipantes(true);
        } else if (component.oid === 8) {
            setOpcionFiltro(3);
            setCodigoProyecto(selectProject.codigo);
            setIsModalVisibleParticipantes(true);
        }else if (component.oid === 9) {
            setOpcionFiltro(4);
            setCodigoProyecto(selectProject.codigo);
            setIsModalVisibleParticipantes(true);
        }
    };

    const procesarMpoa = async (Mpoa) => {
        if (Mpoa) {
            if (Mpoa === '2.2.1') {
                return null;
            }
            const manejarActividadEspecial = (actividad) => {
                switch (actividad) {
                    case '2.2.1.1':
                        return '2.2.1'; 
                    case '2.2.1.2':
                        return '2.2.1';
                    case '2.2.1.3':
                        return '2.2.1'; 
                    default:
                        return actividad; 
                }
            };
            const actividadBase = manejarActividadEspecial(Mpoa);
            try {
                const dataCompleta = await fetchPoaData();
                const dataPoa = dataCompleta.dataPoa;
                const findNodePath = (node, path = []) => {
                    path.push(node);
                    if (node.codigo_tarea === actividadBase && !['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
                        return path;
                    }
                    if (['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
                        if (node.children && node.children.length > 0) {
                            for (let child of node.children) {
                                if (Mpoa === '2.2.1.2' && child.key === 'indicador_33') {
                                    path.push(child);
                                    return path;
                                }
                                if (Mpoa === '2.2.1.3' && child.key === 'indicador_34') {
                                    path.push(child);
                                    return path;
                                }
                            }
                        }
                    }

                    if (node.children && node.children.length > 0) {
                        for (let child of node.children) {
                            const result = findNodePath(child, [...path]);
                            if (result) return result;
                        }
                    }
                    return null;
                };
                let matchedPath = null;

                for (let nodo of dataPoa) {
                    matchedPath = findNodePath(nodo);
                    if (matchedPath) break;
                }
                if (matchedPath) {
                    const initialData = extractData(matchedPath);
                    const actividadData = initialData[0].actividadData;
                    const tareaEncontrada = actividadData.find((item) => {
                        if (['2.2.1.2', '2.2.1.3'].includes(Mpoa)) {
                            return (
                                item.codigo_tarea === actividadBase &&
                                ((Mpoa === '2.2.1.2' && item.key === 'indicador_33') ||
                                    (Mpoa === '2.2.1.3' && item.key === 'indicador_34'))
                            );
                        }
                        return item.codigo_tarea === actividadBase;
                    });
                    if (tareaEncontrada) {
                        tareaEncontrada.proyecto = selectProject.codigo;
                        setOpenModalVisitasEcas(true);
                        setItemParams(tareaEncontrada);
                    } else {
                        setOpenModalVisitasEcas(false);
                        setItemParams(null);
                    }
                } else {
                    console.warn("No se encontró ningún nodo coincidente");
                }
            } catch (error) {
                console.error("Error al obtener los datos:", error);
            }
        } else {
            setOpenModalVisitasEcas(false);
        }
    };

    const extractData = (children) => {
        return children.flatMap(child => {
            if (child.children && child.tipo === 'Actividad') {
                const actividadData = child.children.flatMap(grandchild => {
                    if (grandchild.children && grandchild.tipo === 'Tarea') {
                        return grandchild.children.flatMap(greatGrandchild => {
                            if (greatGrandchild.tipo === 'Indicador') {
                                const fecha = greatGrandchild.meta_e?.[0]?.fecha;
                                const fechaFormateada = fecha ? new Date(fecha).toLocaleDateString('es-ES') : '-';
                                const medioV = greatGrandchild.medioVerificacion || null;
                                return {
                                    key: greatGrandchild.key,
                                    codigo_tarea: grandchild.codigo_tarea,
                                    codActividad: grandchild.codActividad,
                                    nameActividad: child.actividad,
                                    tarea: grandchild.tarea,
                                    indicador: greatGrandchild.title,
                                    medioV: medioV,
                                    fechas: fechaFormateada,
                                    responsables: 'N/A',
                                    soportes: 'N/A',
                                    datoIndicador: greatGrandchild,
                                };
                            }
                            return [];
                        });
                    }
                    return [];
                });
                return [{
                    actividadTitle: child.title,
                    actividad: child.actividad,
                    actividadData: actividadData
                }];
            }
            return [];
        });
    };

    const hideModalVisitasEcas = () => {
        setOpenModalVisitasEcas(false);
    };


    const handleCancelParticipantes = () => {
        setIsModalVisibleParticipantes(false);
    };

    const downloadFileHandler = async () => {
        const loadingMessage = message.loading('Cargando archivo...', 0);
        try {
            const proyect = selectProject.codigo;
            const ruta = `/DocumentosActualizacion/${proyect}/Proyecto/Factibilidad Actualizada PDF/`;
            await viewFile(ruta);
            loadingMessage();
            message.success('Archivo cargado correctamente!', 2);
        } catch (error) {
            console.error('Error al abrir el archivo:', error);
            loadingMessage(); 
            message.error('No se encontró el archivo.', 2);
        }
    };

    const handleBackClick = () => {
        setSelectedComponent(null);
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const handleFaseClick2 = (parentComponent, fase) => {
        setSelectedFase2(fase); 
        setIsModalVisible2(true);
        handleCloseModal();
    };

    const handleCloseModal2 = () => {
        setIsModalVisible2(false);
        //setSelectedProject(null);
    };

    const renderComponent = () => {
        switch (opcionFiltro) {
          case 1:
            return <Participantes proyectoCodigo={codigoProyecto} />;
          case 2:
            return <Ippta proyectoCodigo={codigoProyecto} />;
          case 3:
            return <Edah proyectoCodigo={codigoProyecto} />;
          case 4: 
            return <AcuerdosComerciales proyectoCodigo={codigoProyecto} />;
          default:
            return null; 
        }
    };

    return (
        <div className="container">
            <Layout>
                <div className="row mb-2 d-flex align-items-center">
                    <div className="col-md-8 linea_separador mb-2 d-flex align-items-center">
                        <div className="titulo_proyecto" style={{ flexBasis: '25%', flexGrow: 0 }}>
                            <p>PROYECTO UTF <br /> COL 160 COL</p>
                        </div>
                        <div className="objeto" style={{ flexBasis: '75%', flexGrow: 0 }}>
                            <p>
                                Implementar actividades de desarrollo económico, derecho humano a la alimentación y fortalecimiento de capacidades
                                institucionales, comunitarias y organizacionales que aporten al cierre de brechas y la transformación de los territorios PDET
                            </p>
                        </div>
                    </div>
                    <div className="col-md-4 d-flex justify-content-center align-items-center flex-column">
                        <h2 className="text-center mb-2">Implementación</h2>
                    </div>
                </div>
                <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
                    <Col span={24}>
                        <Input
                            placeholder="Buscar por nombre"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{ width: 400, height: 40, marginRight: 8 }}
                        />
                        <Button
                            onClick={() => setSearchTerm("")}
                            type="primary"
                            style={{ height: 40 }}
                        >
                            Limpiar Filtro
                        </Button>
                    </Col>
                </Row>
                <Row gutter={[16, 16]} className="proyecto-card-unique-row">
                    {loading ? (
                        <div>Cargando proyectos...</div>
                    ) : (
                        filteredProjects.map((project) => (
                            <Col
                                key={project.oid}
                                xs={24}
                                sm={12}
                                md={8}
                                lg={8}
                                xl={6}
                                className="proyecto-card-unique-col"
                            >
                                <Card
                                    className="card-menu h-100 redondear card-header align-items-center text-center"
                                    style={{
                                        body: {
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "column",
                                        },
                                    }}
                                >
                                    <div
                                        style={{
                                            height: "60px",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            padding: "10px",
                                            textAlign: "center",
                                            borderRadius: "5px",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <div >
                                            <h6 className="card-title fw-bold fs-8 m-0" style={{ color: '#FFF !important' }}>{project.proyecto}</h6>
                                            <p className="card-text"><small className="codproyecto">Código: {project.codigo}</small></p>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flex: 1,
                                            width: "100%",
                                            overflow: "hidden",
                                        }}
                                    >
                                        <Row gutter={16} style={{ width: "100%", justifyContent: "center" }}>
                                            <div className="col-md-12" style={{ maxHeight: '130px', width: '100%', height: '100%' }}>
                                                <ImageComponent
                                                    projectId={project.oid} />
                                            </div>
                                        </Row>
                                    </div>
                                    <div
                                        className="text-muted mt-auto"
                                        style={{
                                            display: "flex",
                                            backgroundColor: "#fff",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: "auto",
                                            padding: "10px",
                                        }}
                                    >
                                        <Tooltip title="Ingresar">
                                            <Button
                                                className="m-2 btn-primary-lis"
                                                onClick={() => handleOpenModal(project)}
                                                style={{
                                                    background: "none",
                                                    border: "none",
                                                    color: "inherit",
                                                }}
                                                icon={<HomeOutlined style={{ fontSize: 20, color: "#1890ff" }} />}
                                            />
                                        </Tooltip>
                                    </div>
                                </Card>
                            </Col>
                        ))
                    )}
                </Row>
                {selectProject && (
                    <Modal
                        title={<h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>{selectProject.codigo} - {selectProject.proyecto}</h4>}
                        open={isModalVisible}
                        onCancel={handleCloseModal}
                        footer={null}
                        width={1200}
                    >
                        <div style={{ paddingTop: '20px' }}>
                            {selectedComponent ? (
                                <div>
                                    <Row gutter={[16, 16]}>
                                        {selectedComponent.fases && selectedComponent.fases.length > 0 ? (
                                            selectedComponent.fases.map((fase) => (
                                                <Col xs={24} sm={12} md={8} key={fase.oid}>
                                                    <Tooltip title={fase.nombre_largo}>
                                                        <Card
                                                            hoverable
                                                            style={{
                                                                paddingTop: "20px",
                                                                borderRadius: "8px",
                                                                textAlign: "center",
                                                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                                            }}
                                                            onClick={() => handleFaseClick2(selectedComponent, fase)}
                                                        >
                                                            <div
                                                                style={{
                                                                    marginTop: "20px",
                                                                    width: "80px",
                                                                    height: "80px",
                                                                    borderRadius: "50%",
                                                                    margin: "0 auto 10px",
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                    alignItems: "center",
                                                                    fontSize: "24px",
                                                                    fontWeight: "bold",
                                                                    backgroundColor: fase.color,
                                                                    color: "#000",
                                                                }}
                                                            >
                                                                {fase.sigla}
                                                            </div>
                                                            <h5>{fase.nombre_corto}</h5>
                                                            <p style={{ color: "gray", margin: "5px 0" }}>{fase.nombre_largo}</p>
                                                        </Card>
                                                    </Tooltip>
                                                </Col>
                                            ))
                                        ) : (
                                            <div>No hay fases disponibles</div>
                                        )}
                                    </Row>
                                    <Row justify="center" style={{ marginBottom: "20px", marginTop: '20px' }}>
                                        <Button icon={<ArrowLeftOutlined />} onClick={handleBackClick}>
                                            Regresar
                                        </Button>
                                    </Row>
                                </div>
                            ) : (
                                <Row gutter={[12, 12]}>
                                    {componentes.map((item) => (
                                        <Col xs={12} sm={16} md={6} key={item.oid}>
                                            <Tooltip title={item.nombre_largo}>
                                                <Card
                                                    hoverable
                                                    style={{
                                                        borderRadius: "8px",
                                                        paddingTop: "20px",
                                                        textAlign: "center",
                                                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                                        width: "100%",
                                                        marginTop: "20px",
                                                    }}
                                                    onClick={() => {
                                                        handleCardClick(item); 
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            marginTop: "20px",
                                                            width: "80px",
                                                            height: "80px",
                                                            borderRadius: "50%",
                                                            margin: "0 auto 20px",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            fontSize: "24px",
                                                            fontWeight: "bold",
                                                            color: "#000",
                                                            backgroundColor: item.oid > 9 ? "#d3d3d3" :  item.color,
                                                            cursor: item.oid > 9 ? "not-allowed" : "pointer",
                                                        }}
                                                    >
                                                        {item.sigla}
                                                    </div>
                                                    <h6>{item.nombre_corto}</h6>
                                                </Card>
                                            </Tooltip>
                                        </Col>
                                    ))}
                                </Row>
                            )}
                        </div>
                    </Modal>)}
                <Modal
                    title=""
                    open={isModalVisible2}
                    onCancel={handleCloseModal2}
                    footer={null}
                    width="95%" 
                    style={{ top: 10 }}
                >
                    <PoaPlanOperativoTB selectedFase={selectedFase2} selectedProject={selectProject} regresarAnterior={regresarModal} cerrarModal={handleCloseModal2} />
                </Modal>
                <div>
                    <Modal
                        title=""
                        open={isModalVisibleParticipantes}
                        onCancel={handleCancelParticipantes}
                        footer={null}
                        width="95%" 
                        style={{ top: 10 }}
                    >
                          {renderComponent()}
                    </Modal>
                </div>
                <div className="d-flex justify-content-center">
                    <Modal
                        title={<span className="custom-modal-title">Medios de verificación</span>}
                        open={openModalVisitasEcas}
                        onCancel={hideModalVisitasEcas}
                        footer={null}
                        width="95%"
                        style={{ top: 20 }}
                    >
                        <FileManagerPOAN codigo_tarea={itemParams} />
                    </Modal>
                </div>
            </Layout>
        </div>
    );
};
export default PoaPlanOperativo;
