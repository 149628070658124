import React, { useState, useEffect } from "react";
import { Input } from "antd";

const { TextArea } = Input;

const EditableCell = ({
  title,
  editable,
  children,
  value,
  text, // Ahora tienes acceso al valor de 'text'
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value || ""); // Inicializa con el valor actual o una cadena vacía

  useEffect(() => {
    if (editing) {
      console.log("title", title);
      console.log("value", value);
      console.log("text", text);
      setInputValue(text || value || ""); // Usa el valor de 'text' para establecer el valor inicial
    }
  }, [editing, value, text]); // Dependencias de useEffect

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const save = () => {
    handleSave({ ...record, [title]: inputValue }); // Guarda los cambios
    setEditing(false); // Desactiva el modo de edición
  };

  const toggleEdit = () => {
    setEditing(true); // Activa el modo de edición
  };

  return (
    <td
      {...restProps} // Incluye rowSpan automáticamente
      onDoubleClick={editable ? toggleEdit : undefined} // Usa doble clic para activar la edición
    >
      {editing ? (
        <TextArea
        value={inputValue}
        onChange={handleChange}
        onBlur={save} 
        autoSize={{ minRows: 3, maxRows: 60 }} 
        style={{
          heeight: 'auto', 
          width: '400px',  
          maxWidth: '100%', 
          minHeight: '60px',
          maxHeight: '300px', 
        }}
        autoFocus
      />
      
      ) : (
        <div>{children || value || "-"}</div> // Muestra el contenido existente o un marcador
      )}
    </td>
  );
};

export default EditableCell;
