import React, { useEffect, useState } from "react";
import { Tabs, Form, Input, Select, Row, Col, Button, message, DatePicker, InputNumber } from "antd";
import { getAllSedes, getAllRectorias, getAllNiveles, getAllUsers, updateMatriculado, createMatriculado } from '../../services/cursoFortalecimiento/cursoFortalecimientoCVService';

import dayjs from 'dayjs';
const { Option } = Select;

const FormCaracterizacion = ({ registroData, resetSignal, onSuccess, onPendingChanges, activeTabKey, setActiveTabKey }) => {
    
    const [form] = Form.useForm();
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [, setIsFormChanged] = useState(false);
    const [, setHasUnsavedChanges] = useState(false);
    const [sedes, setSedes] = useState([]);
    const [rectorias, setRectorias] = useState([]);
    const [niveles, setNiveles] = useState([]);
    const [usuarios, setUsuarios] = useState([]);

    useEffect(() => {
        if (registroData) {
            const updatedData = {
                ...registroData,
                nombre_instancia: registroData.oid_nombre_instancia || null,
                id_departamento: registroData.oid_departamento || null,
                id_municipio: registroData.oid_municipio || null,
                instancia_tipo: registroData.oid_tipo_instancia || null,
                tipo_norma: registroData.oid_tipo_norma || null,
                planeacion: registroData.oid_planeacion || null,
                periodicidad: registroData.oid_periodicidad || null,
                fecha_actividad: registroData.fecha_actividad
                    ? dayjs(registroData.fecha_actividad)
                    : null,
            };
            form.setFieldsValue(updatedData);
            setIsUpdateMode(true);
        } else {
            form.resetFields();
            setIsUpdateMode(false);
        }
    }, [registroData, form]);

    useEffect(() => {
  
    }, [form]);

    const validateCurrentTab = async (tabKey) => {
        try {
            const allFields = form.getFieldsValue();
            const requiredFields = Object.keys(allFields).filter((field) => {
                return field.includes(tabKey);
            });
            await form.validateFields(requiredFields);
            return true;
        } catch (err) {
            return false;
        }
    };

    const handleTabChange = async (key) => {
        const isValid = await validateCurrentTab(activeTabKey);
        if (isValid) {
            setActiveTabKey(key);
        } else {
            message.error("Por favor, completa los campos requeridos en este paso.");
        }
    }
    
    const nextTab = async () => {
    const isValid = await validateCurrentTab(activeTabKey);
    if (isValid) {
        setActiveTabKey("2");
    } else {
        message.error("Por favor, completa los campos requeridos en este paso.");
    }
};

    const prevTab = () => {
        setActiveTabKey("1");
    };

    const handleFormChange = () => {
        setIsFormChanged(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [sedes, rectorias, niveles, usuarios] = await Promise.all([
                    getAllSedes(),
                    getAllRectorias(),
                    getAllNiveles(),
                    getAllUsers()
                ]);
                setSedes(sedes);
                setRectorias(rectorias);
                setNiveles(niveles);
                setUsuarios(usuarios);
            } catch (error) {
                console.error('Error al cargar los datos:', error);
            }
        };

        fetchData();
    }, []);

  
    const handleValuesChange = () => {
        setHasUnsavedChanges(true);
        onPendingChanges(true);
    };

    const tabItems = [
        {
            key: "1",
            label: (
                <span>
                    Paso 1
                </span>
            ),
            children: (
                <div>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="periodo_academico"
                                label={<span style={{ fontWeight: 'bold' }}>Periodo Académico</span>}
                                >
                                <InputNumber
                                    placeholder="Ingrese el período académico"
                                    style={{ width: '100%' }}
                                    min={0}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label={<span style={{ fontWeight: 'bold' }}>Fecha actividad</span>}
                                name="fecha_actividad"
                                rules={[{ required: true, message: 'Seleccione la fecha de la actividad' }]}
                            >
                                <DatePicker style={{ width: '100%' }} format="DD/MM/YYYY" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="pidm_persona"
                                label={<span style={{ fontWeight: 'bold' }}>ID Persona</span>}
                            >
                                <InputNumber
                                    placeholder="Ingrese el PIDM"
                                    style={{ width: '100%' }}
                                    min={0}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="prospecto_id"
                                label={<span style={{ fontWeight: 'bold' }}>ID Prospecto</span>}
                            >
                                <InputNumber
                                    placeholder="Ingrese el ID del prospecto"
                                    style={{ width: '100%' }}
                                    min={0}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="identificacion_persona"
                                label={<span style={{ fontWeight: 'bold' }}>Identificación</span>}
                            >
                                <InputNumber
                                    placeholder="Ingrese la identificación"
                                    style={{ width: '100%' }}
                                    min={0}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="nombre_completo" label={<span style={{ fontWeight: 'bold' }}>Nombre Completo</span>}>
                                <Input placeholder="Ingrese el nombre completo" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="telefono_contacto"
                                label={<span style={{ fontWeight: 'bold' }}>Teléfono</span>}
                                rules={[
                                    { pattern: /^[0-9]{7,15}$/, message: 'Ingrese un número válido (7-15 dígitos)' }
                                ]}
                            >
                                <InputNumber
                                    placeholder="Ingrese el número de teléfono"
                                    style={{ width: '100%' }}
                                    min={0}
                                    controls={false}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="correo_electronico"
                                label={<span style={{ fontWeight: 'bold' }}>Correos Electrónicos</span>}
                                rules={[
                                    {
                                        pattern: /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\s*-\s*[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})*$/,
                                        message: 'Ingrese correos válidos separados por " - "'
                                    }
                                ]}
                            >
                                <Input placeholder="Ingrese uno o más correos electrónicos separados por -" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="sede_id"
                                label={<span style={{ fontWeight: 'bold' }}>Sede</span>}
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Select
                                    placeholder="Selecciona un dato"
                                    allowClear
                                >
                                    {sedes.map(sede => (
                                        <Option key={sede.oid} value={sede.oid}>
                                            {`${sede.sede} ${sede.descripcion}`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="rectoria_id"
                                label={<span style={{ fontWeight: 'bold' }}>Rectoría</span>}
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Select
                                    placeholder="Selecciona un dato"
                                    allowClear
                                >
                                    {rectorias.map(rectoria => (
                                        <Option key={rectoria.oid} value={rectoria.oid}>
                                            {`${rectoria.rectoria}`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="nombre_facultad"  label={<span style={{ fontWeight: 'bold' }}>Facultad</span>}>
                                <Input placeholder="Ingrese el nombre de la facultad" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="nivel_id"
                                label={<span style={{ fontWeight: 'bold' }}>Nivel</span>}
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Select
                                    placeholder="Selecciona un dato"
                                    allowClear
                                >
                                    {niveles.map(nivel => (
                                        <Option key={nivel.oid} value={nivel.oid}>
                                            {`${nivel.descripcion}`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            ),
        },
        {
            key: "2",
            label: (
                <span>
                    Paso 2
                </span>
            ),
            children: (
                <div>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item name="numero_documento" label={<span style={{ fontWeight: 'bold' }}>Número de Documento</span>}>
                                <Input placeholder="Ingrese el número de documento" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="snp"  label={<span style={{ fontWeight: 'bold' }}>SNP</span>}>
                                <Input placeholder="Ingrese el número SNP" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="costo_inscripcion" label={<span style={{ fontWeight: 'bold' }}>Costo de inscripción</span>} >
                                <Input placeholder="Ingrese el número SNP" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item
                                name="estado_inscripcion"
                                label={<span style={{ fontWeight: 'bold' }}>Estado de inscripción</span>}
                                rules={[{ required: true, message: "Por favor selecciona una opción" }]}
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                    onChange={(value) => console.log("Valor seleccionado:", value)} // Para verificar que el valor es un entero
                                >
                                    <Option value={1}>Decisión Elaborada</Option>
                                    <Option value={2}>No aplica</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="estadoAdmision"
                                label={<span style={{ fontWeight: 'bold' }}>Estado de Admisión</span>}
                                rules={[{ required: true, message: "Por favor selecciona una opción" }]}
                            >
                                <Select
                                    placeholder="Selecciona una opción"
                                    onChange={(value) => console.log("Valor seleccionado:", value)} // Para verificar que el valor es un entero
                                >
                                    <Option value={1}>Admitido</Option>
                                    <Option value={2}>Matriculado</Option>
                                    <Option value={3}>No aplica</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="codigo_tipo_admision" label={<span style={{ fontWeight: 'bold' }}>Código Tipo de Admisión</span>}>
                                <Input placeholder="Ingrese el código del tipo de admisión" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={8}>
                            <Form.Item name="tipo_admision" label={<span style={{ fontWeight: 'bold' }}>Tipo de Admisión</span>}>
                                <Input placeholder="Ingrese el tipo de admisión" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="numero_aplicacion"  label={<span style={{ fontWeight: 'bold' }}>Número de Aplicación</span>}>
                                <Input placeholder="Ingrese el número de aplicación" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="codigo_materia" label={<span style={{ fontWeight: 'bold' }}>Código de Materia</span>}>
                                <Input placeholder="Ingrese el código de la materia" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="usuario_id"
                                label={<span style={{ fontWeight: 'bold' }}>Usuario</span>}
                                rules={[{ required: true, message: 'Por favor selecciona un dato' }]}
                            >
                                <Select
                                    placeholder="Selecciona un dato"
                                    allowClear
                                >
                                    {usuarios.map(user => (
                                        <Option key={user.oid} value={user.oid}>
                                            {`${user.usuario}`}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            ),
        },
    ];

    const handleCreateOrUpdate = async () => {
        try {
            const values = await form.validateFields();
            const dataInfo = {
                ...values,  // Copia todos los valores del formulario
                fecha_actividad: values.fecha_actividad.format('YYYY-MM-DD'),  // Asegura que la fecha esté en el formato adecuado
            };
            if (isUpdateMode) {
                await updateMatriculado(registroData.matriculado_id, dataInfo);
                message.success("Caracterización actualizada con éxito");
            } else {
                await createMatriculado(dataInfo);
                message.success("Caracterización creada con éxito");
            }

            form.resetFields();
            onSuccess();
            setActiveTabKey(1);
        } catch (err) {
            console.error("Error al guardar:", err);
        }
    };

    return (
        <div>
            <Form form={form} layout="vertical" onFieldsChange={handleFormChange} onValuesChange={handleValuesChange}>

                <Tabs
                    activeKey={activeTabKey}
                    onChange={handleTabChange}
                    items={tabItems}
                />
                <div style={{ marginTop: "16px", textAlign: "right" }}>
                    {activeTabKey === "2" && (
                        <>
                            <Button onClick={prevTab} style={{ marginRight: 8 }}>
                                Atrás
                            </Button>
                            <Button
                                type="primary"
                                onClick={handleCreateOrUpdate}
                            >
                                {isUpdateMode ? "Actualizar" : "Crear"}
                            </Button>
                        </>
                    )}
                    {activeTabKey === "1" && (
                        <Button type="primary" onClick={nextTab}>
                            Siguiente
                        </Button>
                    )}
                </div>
            </Form>
        </div>
    );
};

export default FormCaracterizacion;
