import axios from 'axios';
import config from '../../config';

const baseUrl = config.baseUrl;
const endpoint1 = '/api/headerDetallePO';
const endpoint2 = '/api/poaPO';
const endpoint3 = '/api/update-avancePO';
const endpoint4 = '/api/actualizar-fechaPO';

const getHeader = async (oid_proyecto) => { 
  try {
    const response = await axios.get(`${baseUrl}${endpoint1}?oid_proyecto=${oid_proyecto}`);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    if (error.response?.status === 404) {
      console.error('El proyecto no existe o no se encontró información.');
    } else {
      console.error('Error al obtener los proyectos:', error);
    }
    throw error;
  }
};


const getPOA = async (fase_id, oid_proyecto) => {
  try {
    console.log('fase_id', fase_id);
    console.log('oid_proyecto', oid_proyecto);
    const response = await axios.get(`${baseUrl}${endpoint2}?fase_id=${fase_id}&oid_proyecto=${oid_proyecto}`);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al obtener las actividades:', error);
    throw error; 
  }
};

const updateAvance = async (id, avance) => {
  try {
    const response = await axios.put(`${baseUrl}${endpoint3}`, { id, avance });
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al actualizar el avance:', error);
    throw error;
  }
};

const updateFecha = async (oid, fecha, tipo) => {
  try {
    // Realiza la solicitud PUT al backend
    console.log('oid', oid);
    console.log('fecha', fecha);
    console.log('tipo', tipo);
    
    const response = await axios.put(`${baseUrl}${endpoint4}`, { oid, fecha, tipo });
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    console.error('Error al actualizar la fecha:', error);
    throw error;
  }
};

export { getHeader, getPOA, updateAvance, updateFecha };
